import React from 'react'
import BellTopBar from '../../../../../Components/Bars/BellTopBar'
import ProcedureInsurance from './ProcedureInsuranceForm/Table'
import CancelBar from '../../../../../Components/Steppers/CancelBar'

const ProcedureIns = () => {

  return (

    <div className="w-full h-screen  bg-ghost_white">
        <div className="w-full bg-ghost_white">
          <BellTopBar label="Add Procedure" />
          <CancelBar />
        </div>
        <div className='w-10/12  bg-white   overflow-x-hidden overflow-y-auto mx-auto z-20'>
          <ProcedureInsurance />
        </div>
    </div>
    
  )
}

export default ProcedureIns