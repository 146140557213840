import React, { useEffect, useState } from "react";

import { Table } from "../../../../../../Components/FinalTable";

import TBody from "./TBody";
import THead from "./THeader";

import BtnPlain from "../../../../../../Components/Buttons/BtnPlain";

import { FaPlus } from "react-icons/fa";

import TableContext from "./TContext";

import OtherNotes from "../Modals/OtherNotes";

import useAxios from "../../../../../../Hooks/useAxios";

import useApp from "../../../../../../Hooks/useApp";

const VisitNoteV2 = ({ notesV2, setNotesV2, handleUpdate, visitDetail }) => {
  const [limit, setLimit] = useState(25);
  const [data, setData] = useState([]);
  const [showPagination] = useState(true);
  const [showNotes, setShowNotes] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const [setFixed] = useState({});
  const [note, setNote] = useState(null);

  const { user } = useApp();

  const request = useAxios();

  async function handleAddNote() {
    let res = await request({
      method: "POST",
      url: "hospital/note-v2",
      data: {
        visit_id: parseInt(visitDetail.id),
        user_id: parseInt(user.id),
      },
    });

    if (res === "error") {
      return;
    }
    //handleUpdate();
    setNote(res);
    setShowNotes(true);
  }

  useEffect(() => {
    if (showNotes === false && note === null) {
      return;
    }
    if (showNotes === false) {
      setNote(null);
    }
  }, [showNotes]);

  return (
    <TableContext.Provider
      value={{
        limit,
        setLimit,
        data,
        notesV2,
        setNotesV2,
        visitDetail,
        setData,
        handleUpdate,
        isFiltered,
        setIsFiltered,
        showPagination,
        setFixed,
      }}
    >
      <div className="flex flex-col items-start bg-white p-6 rounded-lg mt-6 mb-4">
        <div className="flex flex-row justify-between w-full">
          <p className="font-DM-Med text-lg uppercase text-BLACK">
            Visit Notes
          </p>
          <div className="">
            <BtnPlain
              bg="primary"
              textColor="white"
              activeBgColor="secondary"
              mt={2}
              click={handleAddNote}
              className="btn"
            >
              <span className="px-2 font-light text-xl">
                <FaPlus />
              </span>
              <span className=" font-pop-reg">Add Notes</span>
            </BtnPlain>
          </div>
          {note !== null && (
            <OtherNotes
              showModal={showNotes}
              setShowModal={setShowNotes}
              n={note}
              handleUpdate={handleUpdate}
            />
          )}
        </div>
        <Table>
          <THead />
          <TBody />
        </Table>
      </div>
    </TableContext.Provider>
  );
};

export default VisitNoteV2;
