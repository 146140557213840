import { BsArrowLeftCircle } from "react-icons/bs";

import heartBeat from "./../../Assets/Images/heartAsset.png";

const FormBackBtn = ({ click, mb = 2, mt = 2 }) => {
  return (
    <div
      className=" w-full flex flex-row   gap-x-2   items-center "
      style={{ marginBottom: `${mb}em`, marginTop: `${mt}em` }}
    >
      <div
        className="flex  flex-row active:opacity-50  gap-x-3 items-center ml-11  cursor-pointer"
        onClick={click}
      >
        <p className=" text-primary text-3xl ">
          <BsArrowLeftCircle />
        </p>
        <p className=" text-black font-DM-Bold underline text-2xl">Back</p>
      </div>
      <div className="flex flex-1"></div>
      <img alt="heart beat z-0" src={heartBeat} className=" " />
    </div>
  );
};

export default FormBackBtn;
