import React from "react";

import HeaderBtn from "../../../../../../../Components/FinalTable/components/Btns/HeaderBtn";

import { TableHeaderRow } from "../../../../../../../Components/FinalTable";

const THead = () => {
  return (
    <div className="w-full">
      <TableHeaderRow>
        <HeaderBtn w={100 / 4} label="NAME" />
        <HeaderBtn w={100 / 4} label="DETAILS" />
        <HeaderBtn w={100 / 4} label="ADDED BY" />
        <HeaderBtn w={100 / 4} label="DATE" />
        <HeaderBtn w={100 / 4} />
      </TableHeaderRow>
    </div>
  );
};

export default THead;
