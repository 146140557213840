import React, { useContext, useState } from "react";

import { TableHeaderRow } from "../../../../../../../../Components/FinalTable";

import HeaderBtn from "../../../../../../../../Components/FinalTable/components/Btns/HeaderBtn";

import AllContext from "../../AllContext";

const THead = () => {
  const { handleHeaderQuery } = useContext(AllContext);

  const [order, setOrder] = useState("asc");

  function handle(name) {
    handleHeaderQuery(name, order);
    setOrder(order === "asc" ? "desc" : "asc");
  }

  return (
    <TableHeaderRow>
      <HeaderBtn
        w={95 / 5}
        label="VISIT-ID"
        click={() => handle("id")}
      />
      <HeaderBtn w={95 / 5} label="CHECK-IN DATE" />
      <HeaderBtn w={95 / 5} label="PATIENT" />
      <HeaderBtn w={95 / 5} label="PRIORITY" />
     
      <div style={{ width: "5%" }}></div>
    </TableHeaderRow>
  );
};

export default THead;
