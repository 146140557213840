import React, { useState, useEffect } from "react";
import {
  TableCell,
  TableRow,
  TableText,
} from "../../../../../../../Components/FinalTable";

import { convDate } from "./../../../../../../../Util/Time";

import TActions from "./TActions";

import Badge from "../../../../../../../Components/Badges/Badge";

const TRow = ({ doc }) => {
  return (
    <TableRow key={doc?.id || ""}>
      <TableCell w={95 / 5}>
        <TableText
          label={`Name: ${doc?.team_visits_checked_in_byToteam?.name || ""}`}
        />
        <TableText
          label={`Role: ${doc.team_visits_checked_in_byToteam.team_roles.role}`}
        />
      </TableCell>
      <TableCell w={95 / 5}>
        <TableText label={convDate(doc.created_at)} />
      </TableCell>
      <TableCell w={95 / 5}>
        <TableText label={doc.visit_types.name} />
        {doc.visit_types.name === "Referral" && (
          <TableText label={`NO: ${doc.referral_no}`} />
        )}
      </TableCell>
      <TableCell w={95 / 5}>
        <TableText label={doc.default_payment_method} />
        {doc.default_payment_method === "Insurance" && (
          <>
            <TableText label={`${doc.insurance_scheme.name}`} />
            <TableText label={`${doc.insurance_scheme.no}`} />
          </>
        )}
      </TableCell>
      <TableCell w={95 / 5}>
        {doc.checked_out === true ? (
          <>
            <Badge label={`Checked-Out`} bg={"pale_spring_bud"} />
            <TableText
              mt={0.5}
              label={convDate(parseInt(doc.check_out_time))}
            />
          </>
        ) : (
          <Badge label={"Checked In"} bg={"pale_pink"} />
        )}
      </TableCell>
      <div style={{ width: "5%" }} className=" ">
        <TActions doc={doc} />
      </div>
    </TableRow>
  );
};

export default TRow;
