import BtnTemplate from "../../../../../../Components/SideBar/BtnTemplate";

const MenuBtn = ({
  btn_id = 0,
  btn_active = 0,
  func = () => {},
  label = "menu Btn",
  bar = false,
}) => {
  return (
    <BtnTemplate
      label={label}
      open={true}
      active={btn_id === btn_active}
      click={() => func(btn_id)}
    />
  );
};

export default MenuBtn;
