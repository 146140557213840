import React, { useEffect, useState } from 'react'

import { TableCell, TableText, TableRow } from '../../../../../../../Components/FinalTable'

import usePouch from '../../../../../../../Hooks/usePouch'

import { convDate } from '../../../../../../../Util/Time'

import TableActions from './TableActions'

const TRow = ({ doc }) => {
  return (
    <>
      <TableRow key={doc.id}>
        <TableCell w={100 / 4}>
          <TableText label={doc.name} />
        </TableCell>
        <TableCell w={100 / 4}>
          <TableText label={doc.description} />
        </TableCell>
        <TableCell w={100 / 4}>
          <TableText label={` ${doc.team && doc.team.name}`} />
        </TableCell>
        <TableCell w={100 / 4}>
          <TableText label={convDate(doc.created_at)} />
        </TableCell>
        <TableCell w={100 / 4}>
            <TableActions doc={doc}  />
          </TableCell>
      </TableRow>
    </>
  )
}

export default TRow