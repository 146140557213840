import React from "react";

import {
  TableCell,
  TableRow,
  TableText,
} from "../../../../../../../../Components/FinalTable";

import { convDate } from "../../../../../../../../Util/Time";

import Badge from "../../../../../../../../Components/Badges/Badge";
const TRow = ({ doc }) => {
  return (
    <TableRow>
      <TableCell w={95 / 5}>
        <TableText label={`vis-${doc.visit_steps.visits.id}`} />
      </TableCell>
      <TableCell w={95 / 5}>
        <TableText label={convDate(doc.visit_steps.visits.created_at)} />
      </TableCell>
      <TableCell w={95 / 5}>
        <TableText
          label={`${doc.visit_steps.visits.patients.first_name} ${doc.visit_steps.visits.patients.second_name}`}
        />
      </TableCell>

      <TableCell w={95 / 5}>
        <Badge
          label={doc.visit_steps.visits ? "High" : "Normal"}
          bg={doc.visit_steps.visits ? "pale_pink" : "bright_grey"}
        />
      </TableCell>
   
   
    </TableRow>
  );
};

export default TRow;
