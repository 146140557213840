const btns = [
  "Sign And Symptoms",
  "HPI",
  "Gynecological History",
  "Family Social History",
  "Nutritional History",
  "Past Medical History",
  "Systemic Review",
  "Impression",
  "Investigation",
  "Diagnosis",
  "Treatment",
];

export default btns;
