import React, { useState, useEffect } from "react";

import { colors } from "./../../Util/colors";

const NumberInput = ({
  input,
  inputKey,
  initial = 0,
  mt = 0,
  mb = 0,
  showError = 0,
  config = {
    disabled: false,
    required: true,
    label: "Number input",
    placeholder: "",
    max: 999999999,
    min: 0,
  },
}) => {
  const [noInput, setNoInput] = useState(initial);
  const [borderColor, setBorderColor] = useState("bright_grey");

  const [err, setErr] = useState(false);

  const [errMessage, setErrorMessage] = useState("Field Required");

  useEffect(() => {
    input.current.form[inputKey] = initial;
    setNoInput(initial);
  }, []);

  useEffect(() => {
    if (showError === 0) {
      return;
    }
    handleBlur();
  }, [showError]);

  function handleChange(n) {
    if (parseInt(n) > config.max || parseInt(n) < config.min) {
      return;
    }
    setNoInput(n);
    input.current.form[inputKey] = n;
  }

  function handleBlur() {
    if (config.required === false) {
      return;
    }

    if (noInput === "") {
      setBorderColor("red");
      setErr(true);
      input.current.err[inputKey] = true;
      setErrorMessage(`* Field required`);
      return;
    }

    if (parseInt(noInput) < config.min) {
      setBorderColor("red");
      setErr(true);
      input.current.err[inputKey] = true;
      setErrorMessage(`* Minimum input number is ${config.min}`);
      return;
    }

    input.current.err[inputKey] = false;
    setBorderColor("bright_grey");
    setErr(false);
    setErrorMessage(`* Field required`);
  }

  return (
    <div
      className="flex flex-col"
      style={{
        marginTop: `${mt}em`,
        marginBottom: `${mb}em`,
      }}
    >
      <label className=" font-DM-Reg text-base">{config.label}</label>
      <input
        className={`border-2 rounded-lg w-full py-2 px-8 placeholder-cadet_grey `}
        onFocus={() => setBorderColor("primary")}
        style={{
          borderColor: colors[borderColor],
          cursor: config.disabled ? "not-allowed" : "text",
        }}
        type={"number"}
        placeholder={config.placeholder}
        value={noInput}
        disabled={config.disabled}
        onChange={(e) => handleChange(e.target.value)}
        onBlur={handleBlur}
      />
      <small
        className="text-red font-DM-Reg"
        style={{ opacity: err ? "100%" : "0%" }}
      >
        {errMessage}
      </small>
    </div>
  );
};

export default NumberInput;
