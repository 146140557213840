import React, { useState } from "react";

import { colors } from "./../../../Util/colors";

const Numeric = ({
  input = "",
  setInput = () => {},
  mt = 0,
  mb = 0,
  placeholder = "32423",
  label = "Numeric *",
  disabled = false,
  required = true,
}) => {
  const [borderColor, setBorderColor] = useState("bright_grey");

  return (
    <div
      className="flex flex-col flex-1"
      style={{
        marginTop: `${mt}em`,
        marginBottom: `${mb}em`,
      }}
    >
      <label className=" font-DM-Reg text-base">{label}</label>
      <input
        className={`border-2 rounded-lg w-full py-2 px-8 placeholder-cadet_grey `}
        onFocus={() => setBorderColor("primary")}
        style={{
          borderColor: colors[borderColor],
          cursor: disabled ? "not-allowed" : "text",
        }}
        type={"number"}
        placeholder={placeholder}
        value={input}
        disabled={disabled}
        required={required}
        onChange={(e) => setInput(e.target.value)}
      />
    </div>
  );
};

export default Numeric;
