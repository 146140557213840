

const TotalRequests = ({totalRequests}) => {
    return (
      <div className="shadow-sm rounded-2xl bg-gradient-to-b from-indigo-500 to-rose-400 p-11 flex flex-col justify-between">
              <div className="flex justify-start items-center mb-10 ">
                  <div className="font-DM-Reg text-3xl text-white">
                      <p>TOTAL REQUESTS</p>
                  </div>
              </div>
              <div className="flex justify-between items-center my-10">
                  <div className="font-pop-reg text-7xl font-bold text-white">
                      <p>{totalRequests}</p>
                  </div>
              </div>
          </div>
    )
  }
  
  export default TotalRequests