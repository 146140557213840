import Tooltip from "../../../Components/ToolTip";
import BtnRounded from "../../../../../Components/Buttons/BtnRounded";
import { IoReload } from "react-icons/io5";

const ReloadTip = ({ fetchSteps = () => {} }) => {
  const id = "Reload-tooltip";

  return (
    <div data-tip data-for={id} className="w-full">
      <BtnRounded click={() => fetchSteps()}>
        <IoReload />
      </BtnRounded>
      <Tooltip id={id} txt1="Reload ..." txt2="">
        <IoReload />
      </Tooltip>
    </div>
  );
};

export default ReloadTip;
