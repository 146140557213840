import React from "react";
import { FaUsers } from "react-icons/fa";

const PLab = ({lab}) => {
    return (
        <div className="w-72 h-40 md:w-72  shadow-sm rounded-3xl bg-[#8087d8] p-6">
            <div className="flex justify-between items-center mb-10 ">
                <div className="font-DM-Reg text-xl text-white">
                    <p>Patients in Lab</p>
                </div>
                <div>
                    <FaUsers size="28px" className="text-white" />
                </div>
            </div>
            <div className="flex justify-between items-center my-10 ">
                <div className="font-pop-reg text-5xl text-white">
                    <p>{lab}</p>
                </div>
            </div>
        </div>
    );
};

export default PLab;
