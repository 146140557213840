import React from "react";

import {
  TableCell,
  TableText,
  TableRow,
} from "../../../../../../Components/FinalTable";
import { convDate } from "../../../../../../Util/Time";
import TableActions from "./TableActions";
import Badge from "../../../../../../Components/Badges/Badge";

const TRow = ({ doc }) => {
  //console.log(doc);
  return (
    <>
      <TableRow key={doc.id}>
        <TableCell w={100 / 7}>
          <TableText
            label={` ${
              doc.consumables
                ? doc.consumables.inventory_items.name
                : doc.scheme_consumable.inventory_items.name
            }`}
          />
        </TableCell>
        <TableCell w={100 / 7}>
          <p>
            Dosage: <span className=" font-bold">{doc.dosage || 1}</span>
          </p>
          <p>
            Frequency: <span className=" font-bold">{doc.frequency || 1}</span>
          </p>
          <p>
            No Of Days: <span className=" font-bold">{doc.no_of_day || 1}</span>{" "}
          </p>
        </TableCell>
        <TableCell w={100 / 7}>
          <TableText label={doc.qty} />
        </TableCell>
        <TableCell w={100 / 7}>
          <TableText label={doc.to_pay} />
          <TableText label={`total: ${doc.to_pay * doc.qty}`} />
        </TableCell>
        <TableCell w={100 / 7}>
          <TableText label={doc.payment_method} />
        </TableCell>
        <TableCell w={100 / 7}>
          {doc.prescription_status == "pending" ? (
            <Badge label={doc.prescription_status} bg={"pale_spring_bud"} />
          ) : doc.prescription_status == "canceled" ? (
            <Badge label={doc.prescription_status} bg={"pale_pink"} />
          ) : doc.prescription_status == "complete" ? (
            <Badge label={doc.prescription_status} bg={"bright_grey"} />
          ) : (
            " "
          )}
        </TableCell>
        <TableCell w={100 / 7}>
          <TableActions doc={doc} />
        </TableCell>
      </TableRow>
    </>
  );
};

export default TRow;
