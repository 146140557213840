import React, { useState, useEffect, forwardRef } from "react";
import Logo from "../../../../../../Assets/Images/logobill.svg";
import { useParams } from "react-router-dom";
import useApp from "../../../../../../Hooks/useApp";
import useAxios from "../../../../../../Hooks/useAxios";
import { convDate } from "../../../../../../Util/Time";

const Invoice = forwardRef((props, ref) => {
  const [billingDetails, setBillingDetails] = useState({});
  const [bills, setBills] = useState({});
  const [consultations, setConsultations] = useState([]);
  const [prescriptions, setPrescriptions] = useState([]);
  const [investigations, setInvestigations] = useState([]);
  const [procedures, setProcedures] = useState([]);
  const [othercharges, setOthercharges] = useState([]);
  const [currVisit, setCurrVisit] = useState({});
  const { id } = useParams();
  const request = useAxios();
  const { user } = useApp();

  useEffect(() => {
    async function getDetails() {
      try {
        let res = await request({
          method: "GET",
          url: `hospital/visit/bill?visit_id=${parseInt(props.props)}`,
        });
        setBillingDetails(res);
        setBills(res.bill);
        setConsultations(res.consultations);
        setInvestigations(res.investigations);
        setOthercharges(res.other_charges);
        setPrescriptions(res.prescriptions);
        setProcedures(res.procedures);
        setCurrVisit(res.visit);
      } catch (error) {}
    }

    getDetails();
  }, [props.props]);

  return (
    <div className="w-[90%] h-full  mx-auto" ref={ref}>
      {/* Header Section with logo and text on the right */}

      <div className="w-full flex flex-row justify-between px-10 mb-10">
        <div className="mt-15">
          <img src={Logo} className="w-56" alt="Mkombozi logo" />
        </div>
        <div className="flex flex-col justify-end text-end -mt-10">
          <h1 className="text-3xl font-extrabold text-primary py-7">INVOICE</h1>
          <h3 className="text-xl font-bold  ">MKOMBOZI HOSPITAL</h3>
          <h4 className="text-md font-semibold  ">INV NO: {currVisit.id}</h4>
          <h4 className="text-md font-semibold  ">
            DATE: {convDate(bills.created_at)}
          </h4>
        </div>
      </div>

      <div className="w-full flex flex-row justify-between px-10">
        <div className="mt-15 flex flex-row gap-x-4">
          <h1 className="text-md font-extrabold text-gray-500 py-7">
            BILL TO:{" "}
          </h1>
          <h1 className="text-md font-medium text-black py-7">
            {currVisit.default_payment_method == "Insurance"
              ? currVisit.insurance_scheme.insurance.company_name
              : currVisit.default_payment_method == "Cash"
              ? currVisit.patients.first_name +
                "  " +
                currVisit.patients.sir_name
              : ""}{" "}
          </h1>
        </div>
        <div className="flex flex-col justify-end text-start mt-5">
          <div className="">
            <h5 className="mb-1 text-sm font-medium inline-flex gap-x-4 tracking-tight text-cadet_grey ">
              PATIENT NUMBER:
              <span>
                <p className="mb-1 font-normal text-gray-700 ">
                  {currVisit.patients ? currVisit.patients.id : ""}
                </p>
              </span>
            </h5>
          </div>
          <div className="">
            <h5 className="mb-1 text-sm font-medium inline-flex gap-x-4 tracking-tight text-cadet_grey ">
              PATIENT NAME:
              <span>
                <p className="mb-1 font-normal text-gray-700 ">
                  {currVisit.patients
                    ? currVisit.patients.first_name +
                      "  " +
                      currVisit.patients.sir_name
                    : ""}
                </p>
              </span>
            </h5>
          </div>
          <div className="">
            <h5 className="mb-1 text-sm font-medium inline-flex gap-x-4 tracking-tight text-cadet_grey ">
              MEDICAL SCHEME:
              <span>
                <p className="mb-1 font-normal text-gray-700 ">
                  {currVisit.default_payment_method == "Insurance"
                    ? currVisit.insurance_scheme.name
                    : "None"}
                </p>
              </span>
            </h5>
          </div>
          <div className="">
            <h5 className="mb-1 text-sm font-medium inline-flex gap-x-4 tracking-tight text-cadet_grey ">
              MEMBERSHIP NUMBER:
              <span>
                <p className="mb-1 font-normal text-gray-700 ">
                  {currVisit.member_no ? currVisit.member_no : "---"}
                </p>
              </span>
            </h5>
          </div>

          <div className="">
            <h5 className="mb-1 text-sm font-medium inline-flex gap-x-4 tracking-tight text-cadet_grey ">
              AMOUNT PAID:
              <span>
                <p className="mb-1 font-normal text-gray-700 ">
                  {bills ? bills.amount_paid : ""}
                </p>
              </span>
            </h5>
          </div>
        </div>
      </div>

      <div className="w-full mt-10">
        <div className="w-[90%]  mx-auto bg-white rounded-sm ">
          <div className="p-3">
            <div className="overflow-x-auto">
              <table className="table-auto w-full">
                <thead className="text-xs font-semibold uppercase text-gray-400 bg-gray-50 border">
                  <tr className="border">
                    <th className="p-2 whitespace-nowrap">
                      <div className="font-semibold text-left text-sm">
                        Description
                      </div>
                    </th>
                    <th className="p-2 whitespace-nowrap">
                      <div className="font-semibold text-left text-sm">
                        Price
                      </div>
                    </th>
                    <th className="p-2 whitespace-nowrap">
                      <div className="font-semibold text-left text-sm">
                        Insurance
                      </div>
                    </th>
                    <th className="p-2 whitespace-nowrap">
                      <div className="font-semibold text-center text-sm">
                        Amount
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody className="text-sm divide-y divide-gray-100 border">
                  {consultations.map((item) => (
                    <tr key={item.id}>
                      <td className="p-2 whitespace-nowrap border">
                        <div className="flex items-center">
                          <div className="font-medium text-gray-800 text-md">
                            Consultation
                          </div>
                        </div>
                      </td>
                      <td className="p-2 whitespace-nowrap border">
                        <div className="text-center text-md">{item.amount}</div>
                      </td>
                      <td className="p-2 whitespace-nowrap border">
                        <div className="text-md text-center">
                          {item.insurance ? item.insurance : ""}
                        </div>
                      </td>
                      <td className="p-2 whitespace-nowrap border">
                        <div className="text-md text-center">{item.amount}</div>
                      </td>
                    </tr>
                  ))}

                  {investigations.map((item) => (
                    <tr key={item.id}>
                      <td className="p-2 whitespace-nowrap border">
                        <div className="flex items-center">
                          <div className="font-medium text-gray-800 text-md">
                            {item.investigations
                              ? item.investigations.name
                              : ""}
                          </div>
                        </div>
                      </td>
                      <td className="p-2 whitespace-nowrap border">
                        <div className="text-center text-md">
                          {item.investigations
                            ? item.investigations.regular_price
                            : ""}
                        </div>
                      </td>
                      <td className="p-2 whitespace-nowrap border">
                        <div className="text-md text-center">
                          {item.insurance ? item.insurance : ""}
                        </div>
                      </td>
                      <td className="p-2 whitespace-nowrap border">
                        <div className="text-md text-center">
                          {item.amount ? item.amount : ""}
                        </div>
                      </td>
                    </tr>
                  ))}

                  {procedures.map((item) => (
                    <tr key={item.id}>
                      <td className="p-2 whitespace-nowrap border">
                        <div className="flex items-center">
                          <div className="font-medium text-gray-800 text-md">
                            {item.procedures ? item.procedures.name : ""}
                          </div>
                        </div>
                      </td>
                      <td className="p-2 whitespace-nowrap border">
                        <div className="text-center text-md">
                          {item.procedures ? item.procedures.regular_price : ""}
                        </div>
                      </td>
                      <td className="p-2 whitespace-nowrap border">
                        <div className="text-md text-center">
                          {item.insurance ? item.insurance : ""}
                        </div>
                      </td>
                      <td className="p-2 whitespace-nowrap border">
                        <div className="text-md text-center">
                          {item.amount ? item.amount : ""}
                        </div>
                      </td>
                    </tr>
                  ))}

                  {prescriptions.map((item) => (
                    <tr key={item.id}>
                      <td className="p-2 whitespace-nowrap border">
                        <div className="flex items-center">
                          <div className="font-medium text-gray-800 text-md">
                            {item.consumables
                              ? item.consumables.inventory_items.name
                              : ""}
                          </div>
                        </div>
                      </td>
                      <td className="p-2 whitespace-nowrap border">
                        <div className="text-center text-md">
                          {item.consumables
                            ? item.consumables.inventory_items.unit_cost
                            : ""}
                        </div>
                      </td>
                      <td className="p-2 whitespace-nowrap border">
                        <div className="text-md text-center">
                          {item.insurance ? item.insurance : ""}
                        </div>
                      </td>
                      <td className="p-2 whitespace-nowrap border">
                        <div className="text-md text-center">
                          {item.amount ? item.amount : ""}
                        </div>
                      </td>
                    </tr>
                  ))}

                  {othercharges.map((item) => (
                    <tr key={item.id}>
                      <td className="p-2 whitespace-nowrap border">
                        <div className="flex items-center">
                          <div className="font-medium text-gray-800 text-md">
                            {item.name ? item.name : ""}
                          </div>
                        </div>
                      </td>
                      <td className="p-2 whitespace-nowrap border">
                        <div className="text-center text-md"></div>
                      </td>
                      <td className="p-2 whitespace-nowrap border">
                        <div className="text-md text-center"></div>
                      </td>
                      <td className="p-2 whitespace-nowrap border">
                        <div className="text-md text-center">
                          {item.to_pay ? item.to_pay : ""}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full flex flex-col justify-end items-end ">
        <div className="text-start mt-5 px-20  ">
          <div className="">
            <h5 className="mb-0 text-sm font-medium inline-flex gap-x-2 tracking-tight text-black ">
              Sub Total (excl GST) :
              <span>
                <p className="mb-0 font-normal text-black ">
                  {bills.total ? bills.total : ""}
                </p>
              </span>
            </h5>
          </div>
          <div className="">
            <h5 className="mb-0 text-sm font-medium inline-flex gap-x-2 tracking-tight text-black ">
              Total Insurance :
              <span>
                <p className="mb-0 font-normal text-black ">
                  {bills.total_insurance ? bills.total_insurance : "0"}
                </p>
              </span>
            </h5>
          </div>

          <div className="">
            <h5 className="mb-0 text-sm font-medium inline-flex gap-x-2 tracking-tight text-black ">
              Discount :
              <span>
                <p className="mb-0 font-normal text-black ">
                  {bills.discount ? bills.discount : "0"}
                </p>
              </span>
            </h5>
          </div>

          <div className="">
            <h5 className="mb-0 text-sm font-medium inline-flex gap-x-2 tracking-tight text-black ">
              Amount Paid :
              <span>
                <p className="mb-0 font-normal text-black ">
                  {bills.amount_paid ? bills.amount_paid : "0"}
                </p>
              </span>
            </h5>
          </div>

          <div className="">
            <h5 className="mb-1 text-md font-bold inline-flex gap-x-2 tracking-tight text-primary ">
              Balance :
              <span>
                <p className="mb-1 font-bold text-primary underline underline-offset-8 ">
                  {parseInt(bills.total) -
                    (parseInt(bills.amount_paid) +
                      parseInt(bills.discount) +
                      parseInt(bills.total_insurance))}
                </p>
              </span>
            </h5>
          </div>
        </div>
      </div>

      <div className="w-full flex flex-col justify-start items-start">
        <div className="text-start mt-5 px-20 mb-10 ">
          <div className="">
            <h5 className="mb-1 text-sm font-medium inline-flex gap-x-4 tracking-tight text-black ">
              Payment Status :
              <span>
                <p className="mb-1 font-normal text-black ">
                  {bills.payment_status ? bills.payment_status : ""}
                </p>
              </span>
            </h5>
          </div>
          <div className="">
            <h5 className="mb-1 text-sm font-medium inline-flex gap-x-4 tracking-tight text-black ">
              Checked By: :
              <span>
                <p className="mb-1 font-normal text-black ">{user.name}</p>
              </span>
            </h5>
          </div>
          <div className="">
            <h5 className="mb-1 text-sm font-medium inline-flex gap-x-4 tracking-tight text-black ">
              Patient Signature:
              <span>
                <p className="mb-1 font-normal text-black ">
                  ____________________________________
                </p>
              </span>
            </h5>
          </div>
        </div>
      </div>

      <div className="w-full flex flex-col justify-start ">
        <div className="text-start mt-5 px-20  ">
          <div className="">
            <h5 className="mb-1 text-xs font-normal inline-flex gap-x-4 tracking-tight text-black ">
              P.O. BOX 8763-20339 Nairobi Kenya
            </h5>
          </div>
          <div className="">
            <h5 className="mb-1 text-xs font-normal inline-flex gap-x-4 tracking-tight text-black ">
              TEL: 0798765432
            </h5>
          </div>

          <div className="">
            <span>
              <p className="mb-1 font-bold text-primary ">
                For any questions, please contact us at mkombozihospital.co.tz
              </p>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Invoice;
