import React, { useState, useEffect, useContext } from "react";

import {
  TableHeaderRow,
  TableHeaderBtn,
} from "../../../../../../../Components/FinalTable";

import HeaderBtnSort from "../../../../../../../Components/FinalTable/components/Btns/HeaderBtnSort";

import AllContext from "../../AllContext";

import usePouch from "./../../../../../../../Hooks/usePouch";

const vtype = [1,2,3,4]

const THead = () => {
  const { handleHeaderQuery, showLive, handleFilterQuery, setIsFiltered } =
    useContext(AllContext);

  const [checkIn, setCheckIn] = useState(true);
  const [checkout, setCheckOut] = useState(true);
  const [visitTypeNo, setVisitTypeNo] = useState(0);
  const [visitTypes, setVisitTypes] = useState(true);
  const [payment, setPayment] = useState(true);
  const [checkInDate, setCheckInDate] = useState(true);

  function handleCheckInDateSort() {
    if (showLive) {
          handleHeaderQuery("created_at", checkInDate ? "asc" : "desc");
          setCheckInDate(!checkInDate);
          setIsFiltered(true);
          return;
        }
  }

  function handlePaymentFilter() {
    if (showLive) {
      handleHeaderQuery("default_payment_method", payment ? "asc" : "desc");
      setPayment(!payment);
      setIsFiltered(true);
      return;
    }
  }

  function handleStatusFilter() {
    if (showLive) {
      handleHeaderQuery("checked_out", checkout ? "asc" : "desc");
      setCheckOut(!checkout);
      setIsFiltered(true);
      return;
    }
  }

  function handleVisitTypeFilter() {
    if (showLive) {
      handleHeaderQuery("visit_type_id", visitTypes ? "asc" : "desc");
      setVisitTypes(!visitTypes);
      setIsFiltered(true);
      return;
    }
  }


  
  return (
    <TableHeaderRow>
      <TableHeaderBtn w={95 / 5} label="CHECKED IN BY" />
      <HeaderBtnSort
        w={95 / 5}
        click={handleCheckInDateSort}
        label="CHECK-IN DATE"
      />
      <HeaderBtnSort
        w={95 / 5}
        click={handleVisitTypeFilter}
        label="VISIT TYPE"
      />
      <HeaderBtnSort w={95 / 5} click={handlePaymentFilter} label="PAYMENT" />
      <HeaderBtnSort w={95 / 5} click={handleStatusFilter} label="STATUS" />
      <div style={{ width: "5%" }}></div>
    </TableHeaderRow>
  );
};

export default THead;
