import { useNavigate } from "react-router-dom";

import { ActionBtn } from "../../../../../../../Components/FinalTable";

const TableAction = ({ doc }) => {
  const navigate = useNavigate();

  function handleNext() {
    if (doc.checked_out === true) {
      navigate(`/dashboard/next-step/${doc.id}`);
      return;
    }
  }

  return (
    <div className="flex-1">
      {/* <TableHorizontalActionBtn /> */}
      <ActionBtn showOptions={false} next={handleNext}></ActionBtn>
    </div>
  );
};

export default TableAction;
