import React from "react";

import HeaderBtn from "../../../../../../Components/FinalTable/components/Btns/HeaderBtn";

import { TableHeaderRow } from "../../../../../../Components/FinalTable";

const THead = () => {
  return (
    <div className="w-full">
      <TableHeaderRow>
        <HeaderBtn w={100 / 7} label=" NAME" />
        <HeaderBtn w={100 / 7} label="INFO" />
        <HeaderBtn w={100 / 7} label="QTY" />
        <HeaderBtn w={100 / 7} label="TO PAY" />
        <HeaderBtn w={100 / 7} label="PAYMENT METHOD" />
        <HeaderBtn w={100 / 7} label="STATUS" />
        <HeaderBtn w={100 / 7} label="ACTIONS" />
      </TableHeaderRow>
    </div>
  );
};

export default THead;
