import React from 'react'

import HeaderBtnSort from '../../../../../../../../Components/FinalTable/components/Btns/HeaderBtnSort'

import {
    HorizontalActionBtn,
    TableHeaderRow,
  } from '../../../../../../../../Components/FinalTable'
import HeaderBtn from '../../../../../../../../Components/FinalTable/components/Btns/HeaderBtn'

const THeader = () => {
  return (
    <TableHeaderRow>
      
      <HeaderBtnSort w={90 / 5} label="NAME" />
      <HeaderBtnSort w={90 / 5} label="CODE"  />
      <HeaderBtnSort w={90 / 5} label="PRICE"  />
      <HeaderBtnSort w={90 / 5} label="STORE QUANTITY" />
      <HeaderBtn w={90 / 5}  />
      <div className="flex-1 invisible">
        <HorizontalActionBtn></HorizontalActionBtn>
      </div>
    </TableHeaderRow>
  )
}

export default THeader