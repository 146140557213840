const TableHeader = ({
  children,
  bg,
  showDivider = true,
  h = 1,
  empty = false,
  w = 10,
}) => {
  return (
    <div
      className={`w-full flex flex-row gap-x-5 py-1 items-center ${bg}`}
      style={{
        borderColor: "#DEE2E6",
        // borderTopWidth: h,
        borderBottomWidth: showDivider ? h : 0,
      }}
    >
      {children}
      {empty && <div style={{ width: 10 }}></div>}
    </div>
  );
};

export default TableHeader;
