import React from 'react'
import ConsumableInsurance from './ConsumablesTable/Table'

const ConsumableIns = () => {

  return (

    <div className="w-full h-full  bg-ghost_white">
        <div className="w-full bg-ghost_white">
        </div>
        <div className=' bg-white w-full overflow-x-hidden overflow-y-auto mx-auto z-20'>
          <ConsumableInsurance />
        </div>
    </div>
    
  )
}

export default ConsumableIns