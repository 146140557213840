import { useState } from "react";
import MultiPrescriptionCOntext from "./MultiPrescriptionContext";

import { useNavigate } from "react-router-dom";

import FormLabel from "../../../../../Components/Label/FormLabel";
import { FaUsers } from "react-icons/fa";

import AddSteppers from "./sections/Steppers";

import ConsumableTable from "./sections/ConsumableTable";

import FormSection from "./sections/FormSection";

import NewForm from "./sections/NewForm";

const MultiPrescription = () => {
  const [step, setStep] = useState(1);
  const [openList, setOpenList] = useState(false);
  const [listData, setListData] = useState({});

  const [checkedConsumable, setCheckedConsumable] = useState(-1);

  const [consumable, setConsumable] = useState({});

  const navigate = useNavigate();

  const [prescription, setPrescription] = useState([]);

  function handleBack() {
    if (step === 1) return;
    setStep(step - 1);
  }

  return (
    <MultiPrescriptionCOntext.Provider
      value={{
        step,
        setStep,
        prescription,
        setPrescription,
        checkedConsumable,
        setCheckedConsumable,
        handleBack,
        consumable,
        setConsumable,
        openList,
        setOpenList,
        listData,
        setListData,
      }}
    >
      <div className=" h-full">
        <div className=" w-full h-full flex flex-col">
          <FormLabel label="Add Prescription" mt={2} mb={2}>
            <FaUsers />
          </FormLabel>
          <AddSteppers step={step - 1} close={() => navigate(-1)} />

          {step === 1 && <ConsumableTable />}
          
          {step === 2 && <NewForm listData={listData} handleBack={handleBack} />}
        </div>
      </div>
    </MultiPrescriptionCOntext.Provider>
  );
};

export default MultiPrescription;
