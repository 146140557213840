import React, { useState, useEffect } from "react";

import BellTopBar from "../../../../../../Components/Bars/BellTopBar";

import { TableTopBar } from "../../../../../../Components/FinalTable";

import TFilter from "./Components/TableComponent/TFilter";

import usePouchPagination from "../../../../../../Hooks/usePouchPagination";

import useFuzzySearch from "../../../../../../Hooks/useFuzzySearch";

import { useNavigate } from "react-router-dom";

import AllContext from "./AllContext";

import TableAll from "./Components/TableComponent/Table";

import useApp from "../../../../../../Hooks/useApp";

const AllPatients = () => {
  const navigate = useNavigate();

  const { user } = useApp();
  const [openFilter, setOpenFilter] = useState(false);
  const [limit, setLimit] = useState(10);
  const [data, setData] = useState([]);
  const [showPagination, setShowPagination] = useState(true);

  const [tableIsLoading, setTableIsLoading] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);

  const [searchInput, setSearchInput] = useState("");
  const [fixed, setFixed] = useState({});

  const db_name = "user_queu";

  const initialIndex = ["created_at"];

  const initialSort = [{ created_at: "desc" }];

  const initialSelector = {
    created_at: { $gte: null },
  };

  const searchOptions = {
    keys: ["first_name"],
    minMatchCharLength: 3,
    threshold: 0.5,
  };

  const {
    hasNextPage,
    hasPrevPage,
    handleNext,
    handlePrev,
    handleHeaderQuery,
    handleFilterQuery,
    resetQuery,
    page,
    total,
  } = usePouchPagination({
    db_name,
    limit,
    initialSort,
    initialIndex,
    initialSelector,
    setData,
    setTableIsLoading,
    fixed: { user_id: parseInt(user.id) },
  });

  useFuzzySearch({
    db_name,
    input: searchInput,
    options: searchOptions,
    limit,
    setShowPagination,
    setIsFiltered,
    setTableIsLoading,
    setData,
    fixed,
  });

  async function handleClearFilters() {
    await resetQuery();
    setIsFiltered(false);
    setSearchInput("");
  }

  return (
    <AllContext.Provider
      value={{
        limit,
        setLimit,
        data,
        page,
        hasNextPage,
        hasPrevPage,
        handleNext,
        handlePrev,
        handleHeaderQuery,
        handleFilterQuery,
        isFiltered,
        handleClearFilters,
        total,
        setIsFiltered,
        showPagination,
        setFixed,
        resetQuery,
      }}
    >
    
        <div className="w-full px-7 pb-8">
         
          <TableAll />
     
        </div>
     
    </AllContext.Provider>
  );
};
export default AllPatients;
