import { useState } from "react";
import PrescriptionV2Context from "./PrescriptionV2Context";

import { useNavigate } from "react-router-dom";

import FormLabel from "../../../../../Components/Label/FormLabel";
import { FaUsers } from "react-icons/fa";

import AddSteppers from "./sections/Steppers";

import ConsumableTable from "./sections/ConsumableTable";

import FormSection from "./sections/FormSection";

const AddPrescriptionsV2 = () => {
  const [step, setStep] = useState(1);

  const [checkedConsumable, setCheckedConsumable] = useState(-1);

  const [consumable, setConsumable] = useState(null);

  const navigate = useNavigate();

  const [prescription, setPrescription] = useState({
    dosage: 1,
    no_of_day: 1,
    frequency: 1,
    food_relation: "",
    comments: "",
  });

  function handleBack() {
    if (step === 1) return;
    setStep(step - 1);
  }

  console.log(prescription);

  return (
    <PrescriptionV2Context.Provider
      value={{
        step,
        setStep,
        prescription,
        setPrescription,
        checkedConsumable,
        setCheckedConsumable,
        handleBack,
        consumable,
        setConsumable,
      }}
    >
      <div className=" h-full">
        <div className=" w-full h-full flex flex-col">
          <FormLabel label="Add Prescription" mt={2} mb={2}>
            <FaUsers />
          </FormLabel>
          <AddSteppers step={step - 1} close={() => navigate(-1)} />

          {step === 1 && <ConsumableTable />}
          {step === 2 && <FormSection />}
        </div>
      </div>
    </PrescriptionV2Context.Provider>
  );
};

export default AddPrescriptionsV2;
