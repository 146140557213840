import MenuBtn from "../components/MenuBtn";

import HeaderMenu from "../components/HeaderMenu";

import btns from "./btn_list";

const SideBar = ({ active = 1, setActive = () => {} }) => {
  function btnClick(n) {
    setActive(n);
  }
  return (
    <div className=" flex flex-col gap-y-2 mr-2 border-r-2 border-cadet_grey pr-4">
      {btns.map((btn, id) => (
        <MenuBtn
          bar={true}
          key={id}
          label={btn}
          btn_id={id}
          btn_active={active}
          func={btnClick}
        />
      ))}
    </div>
  );
};

export default SideBar;
