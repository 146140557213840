import React, { useState } from "react";
import { Table } from "../../../../../../Components/FinalTable";
import usePouchPagination from "../../../../../../Hooks/usePouchPagination";
import TBody from "./TBody";
import THead from "./THeader";
import { useNavigate, useParams } from "react-router-dom";
import BtnPlain from "../../../../../../Components/Buttons/BtnPlain";
import { FaPlus } from "react-icons/fa";
import TableContext from "./TContext";
import usePouch from "../../../../../../Hooks/usePouch";

const PrescriptionTable = ({
  prescriptions,
  setPrescriptions,
  handleUpdate,
  visitDetail,
}) => {
  const [limit, setLimit] = useState(25);
  const [data, setData] = useState([]);
  const { id } = useParams();
  const [showPagination, setShowPagination] = useState(true);
  const navigate = useNavigate();
  const { fetchById } = usePouch();
  const [vpic, setvPic] = useState(null);
  const [tableIsLoading, setTableIsLoading] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [fixed, setFixed] = useState({});
  const db_name = "visit_prescription";

  const initialIndex = ["created_at"];

  const initialSort = [{ created_at: "desc" }];

  const initialSelector = {
    created_at: { $gte: null },
  };

  const {
    hasNextPage,
    hasPrevPage,
    handleNext,
    handlePrev,
    handleHeaderQuery,
    handleFilterQuery,
    resetQuery,
    page,
    total,
  } = usePouchPagination({
    db_name,
    limit,
    initialSort,
    initialIndex,
    initialSelector,
    setData,
    setTableIsLoading,
    fixed,
  });

  async function handleClearFilters() {
    await resetQuery();
    setIsFiltered(false);
    setSearchInput("");
  }

  return (
    <TableContext.Provider
      value={{
        limit,
        setLimit,
        data,
        handleUpdate,
        prescriptions,
        setPrescriptions,
        setData,
        vpic,
        setvPic,
        page,
        hasNextPage,
        hasPrevPage,
        handleNext,
        handlePrev,
        handleHeaderQuery,
        handleFilterQuery,
        isFiltered,
        handleClearFilters,
        total,
        setIsFiltered,
        showPagination,
        setFixed,
        resetQuery,
        visitDetail
      }}
    >
      <div className="flex flex-col items-start bg-white p-6 rounded-lg mt-6 mb-4">
        <div className="flex flex-row justify-between w-full">
          <p className="font-DM-Med text-lg uppercase text-BLACK">
            Visit Prescriptions
          </p>
          <div className="">
            <BtnPlain
              bg="primary"
              textColor="white"
              activeBgColor="secondary"
              mt={2}
              click={() => navigate("add/multiprescriptions")}
              className="btn"
            >
              <span className="px-2 font-light text-xl">
                <FaPlus />
              </span>
              <span className=" font-pop-reg">Add Prescription Cash</span>
            </BtnPlain>
            
          </div>
        </div>
        <Table>
          <THead />
          <TBody />
          {/* <TInfo /> */}
        </Table>
      </div>
    </TableContext.Provider>
  );
};

export default PrescriptionTable;
