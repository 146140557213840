import React from "react";

import { Table } from "../../../../../../../../Components/FinalTable";

import THead from "./THead";

import TBody from "./TBody";

import TInfo from "./TInfo";

const TableAll = () => {
  return (
    <Table>
      <THead />
      <TBody />
      {/* <TInfo /> */}
    </Table>
  );
};

export default TableAll;
