import React, { useContext } from "react";

import {
  TableCell,
  TableRow,
  TableText,
  SingleCheckBox,
} from "./../../../../../../../Components/FinalTable";

const TRow = ({
  doc,
  checkedConsumable,
  setCheckedConsumable,
  setConsumable,
}) => {
  return (
    <TableRow>
      <TableCell w={100 / 6}>
        <TableText label={doc.inventory_items.code} />
      </TableCell>
      <TableCell w={100 / 6}>
        <TableText label={doc.inventory_items.name} />
      </TableCell>
      <TableCell w={100 / 6}>
        <TableText label={doc.inventory_items.unit_of_measures.name} />
      </TableCell>
      <TableCell w={100 / 6}>
        <TableText label={doc.inventory_items.store_qty} />
      </TableCell>
      <TableCell w={100 / 6}>
        <TableText label={doc.inventory_items.unit_cost} />
      </TableCell>
      <TableCell w={100 / 6}>
        <SingleCheckBox
          checked_id={checkedConsumable}
          setCheckedId={setCheckedConsumable}
          setDoc={setConsumable}
          doc={doc}
          id={doc.id}
        />
      </TableCell>
    </TableRow>
  );
};

export default TRow;
