import React, { useState, useEffect } from "react";
import { SlArrowRightCircle } from "react-icons/sl";

import { FaUserEdit } from "react-icons/fa";

import { MdDelete } from "react-icons/md";

import EditKin from "./EditKin";
import DeleteKin from "./DeleteKin";

import usePouch from "../../../../../../../Hooks/usePouch";

const Details = ({
  activeKin,
  setActiveKin,
  kins,
  setKins,
  relations,
  init,
}) => {
  const [showEdit, setShowEdit] = useState(false);
  const [showDel, setShowDel] = useState(false);

  function handleNext() {
    if (activeKin + 1 > kins.length - 1) {
      setActiveKin(0);
      return;
    }
    setActiveKin((c) => c + 1);
  }

  if (kins[activeKin] == null) return null;

  return (
    <>
      {" "}
      <div className=" w-full">
        <div className="mt-5 pl-4">
          <h6 className="font-medium">NEXT OF KIN {activeKin + 1}</h6>
          <div className="mt-5">
            <h5 className="mb-2 text-xl font-medium tracking-tight text-cadet_grey ">
              NAME
            </h5>
            <p className="mb-3 font-normal text-gray-700 ">
              {kins[activeKin].name}
            </p>
          </div>
          <div className="mt-5">
            <h5 className="mb-2 text-xl font-medium tracking-tight text-cadet_grey ">
              PHONE
            </h5>
            <p className="mb-3 font-normal text-gray-700 ">
              {kins[activeKin].phone}
            </p>
          </div>
          <div className="mt-5">
            <h5 className="mb-2 text-xl font-medium tracking-tight text-cadet_grey ">
              GENDER
            </h5>
            <p className="mb-3 font-normal text-gray-700 ">
              {kins[activeKin].gender}
            </p>
          </div>
          <div className="mt-5">
            <h5 className="mb-2 text-xl font-medium tracking-tight text-cadet_grey ">
              RELATIONSHIP
            </h5>
            <p className="mb-3 font-normal text-gray-700 ">
              {kins[activeKin].relationships == null
                ? ""
                : kins[activeKin].relationships.name}
            </p>
          </div>
        </div>
        <div className="flex  justify-between absolute bottom-10 w-full items-center ">
          <div className=" flex gap-x-2 ">
            <span
              onClick={() => setShowEdit(true)}
              className="text-primary cursor-pointer active:opacity-50  text-3xl pl-2"
            >
              <FaUserEdit />
            </span>
            <span
              onClick={() => setShowDel(true)}
              className="text-red active:opacity-50 cursor-pointer  text-3xl pl-2"
            >
              <MdDelete />
            </span>
          </div>
          <div
            className=" flex active:opacity-50  mr-10 cursor-pointer"
            onClick={handleNext}
          >
            <p className="font-bold text-xl underline">NEXT</p>
            <span className="text-primary text-3xl pl-2">
              <SlArrowRightCircle />
            </span>
          </div>
        </div>
        {showEdit && (
          <EditKin
            showModal={showEdit}
            setShowModal={setShowEdit}
            setKins={setKins}
            kin={kins[activeKin]}
            relations={relations}
            init={init}
          />
        )}
        {showDel && (
          <DeleteKin
            setShowModal={setShowDel}
            showModal={showDel}
            setKins={setKins}
            kins={kins}
            activeKin={activeKin}
            setActiveKin={setActiveKin}
          />
        )}
      </div>
    </>
  );
};

export default Details;
