import FilterModal from "../../../../../../Components/Modals/FilterModal";
import SelectInput from "../../../../../../Components/Inputs/SelectInput"
import DateRangePicker from "../../../../../../Components/Inputs/DateRangePicker";
import { useRef } from "react";
import useAllRequests from "../hooks/useAllRequests";
import { isoDate, convDate2 } from "../../../../../../Util/Time";

const Filter = ({ open, setOpen }) => {
  const input = useRef({
    form: {
      item_type: "",
      from_date: "",
      to_date: "",
    },
    err: {},
  });
 

  const { handleFilterQuery } = useAllRequests();

  let categories = [
    {value : "", label: "Select Category" },
    {value : "inventory", label: "Inventory Items" },
    {value : "fixed", label: "Fixed Assets" },
  ]

  async function applyFilters() {

      let filters = {};
      let to_filter = false;

      if (input.current.form.from_date !== "") {
        filters.filter_start_date = isoDate(input.current.form.from_date)
        to_filter = true
      }

      if (input.current.form.to_date !== "") {
        filters.filter_end_date = isoDate(input.current.form.to_date)
        to_filter = true;
      }
     
      if (input.current.form.item_type === "inventory") {
        filters.filter_is_inventory = true
        to_filter = true;
      }
      if (input.current.form.item_type === "fixed") {
        filters.filter_is_inventory = false
        to_filter = true;
      }
       

    if (to_filter) {
      handleFilterQuery(filters);
      setOpen(false);
      return;
    }
  }

  return (
    <FilterModal showFilter={open} setShowFilter={setOpen} apply={applyFilters}>
      <div className="flex flex-col py-6 gap-y-4">
        <h3 className="uppercase font-DM-Reg font-normal text-sm text-auro_metal_saurus">
          ITEM TYPE
        </h3>
        <div className="w-full">
          <div className=" flex flex-col gap-y-2 ">
            <SelectInput 
              input={input}
              inputKey='item_type'
              mt={0.5}
              config={{
                required: true,
                firstDisabled: true,
                items: categories,
                label: "",
              }}
            />

          </div>
        </div>
      </div>

      <div className="flex flex-col py-6 gap-y-4">
        <h3 className="uppercase font-DM-Reg font-normal text-sm text-auro_metal_saurus">
          date created
        </h3>
        <DateRangePicker
          input={input}
          inputKey1="from_date"
          inputKey2="to_date"
          initial1={convDate2(Date.now())}
          initial2={convDate2(Date.now())}
          mt={0}
          mb={0}
          config={{
            required: true,
            disabled: false,
            label: null,
            max: null,
            min: null,
          }}
        />
      </div>
    </FilterModal>
  );
};

export default Filter;
