import React from "react";
import ConsumableCash from "./ConsumablesTable/Table";

const ConsumableCsh = () => {
  return (
    <div className="w-full h-full  bg-ghost_white">
      <div className="w-full bg-ghost_white"></div>
      <div className=" bg-white w-full overflow-x-hidden overflow-y-auto mx-auto z-20">
        <ConsumableCash />
      </div>
    </div>
  );
};

export default ConsumableCsh;
