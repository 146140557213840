import { TableRow, TableCell, TableText } from "../../../../../../../../Components/FinalTable";

import { convDate2 } from "../../../../../../../../Util/Time";

import Badge from "../../../../../../../../Components/Badges/Badge";

const TableDetails=({ data})=>{
    return(
        <div>
            { data.map((docs, i)=>(
                 <TableRow mt={5} key={i} >
                    <TableCell w={100 / 5}>
                         <TableText label={
                            convDate2(docs.created_at).replaceAll("-", "/")
                        }/>
                     </TableCell>
                     <TableCell w={100 / 5}>
                         <TableText label={docs.investigations.name}/>
                     </TableCell>
                     <TableCell w={100 / 5}>
                         <TableText label={docs.payment_method}/>
                     </TableCell>
                     <TableCell w={100 / 5}>
                        <Badge
                            bg = {docs.procedure_status === "complete" ? "bright_grey" :"pale_pink"}
                            textColor = {docs.procedure_status === "complete" ? "primary" :"red"}
                            label = {docs.procedure_status === "complete" ? " Complete " :" Pending "}
                        />
                     </TableCell>
                 </TableRow>
             

            ))}
        </div>
    )
};

export default TableDetails;
