import React, { useState } from "react";

import LiveData from "./LiveData";

import PouchData from "./PouchData";

const DoctorQueue = ({userQueue}) => {
  const [showLive, setShowLive] = useState(true);
  if (showLive) {
    return <LiveData showLive={showLive} setShowLive={setShowLive} userQueue={userQueue} />;
  }

  return <PouchData showLive={showLive} setShowLive={setShowLive} />;
};

export default DoctorQueue;
