import { useEffect, useState } from "react";
import useAxios from '../../../../../../../../Hooks/useAxios';

import Infosection from "./Infosection";
import TableDetails from "./TableDetails";
import TableHeading from "./TableHeading";

import { Table } from "../../../../../../../../Components/FinalTable";

import ProceduresAll from "../../context/ProceduresContext";

import { useNavigate } from "react-router-dom";

const ProcedureTable = ({ url, id }) => {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);

  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);

  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPrevPage, setHasPrevPage] = useState(false);

  const db_name = "visit_procedure";
  
  const request = useAxios();

  const navigate = useNavigate();
  
  const Title = [
    { id: 1, name: "DATE" },
    { id: 2, name: "CODE" },
    { id: 3, name: "NAME" },
    { id: 4, name: "PAYMENT" },
    { id: 5, name: "STATUS" },
  ];
  const showPagination = true;

  useEffect(() => {
    const getData = async () => {
      const doc = await request({
        url: `patient/history?patient_id=${id}&type=${db_name}&page=${page}&limit=${limit}`,
      });

      if ( doc === "error" ){
        navigate("/dashboard/user-queue");
        return;
      }

      setData(doc.docs);

      setHasNextPage(doc.hasNextPage);

      setHasPrevPage(doc.hasPrevPage);

      setTotal(doc.total_docs);
    };

    getData();
    // eslint-disable-next-line
  }, [limit, page]);

  useEffect(() => {
    setPage(1);
  }, [limit]);

  function handleNext() {
    setPage(page + 1);
  }

  function handlePrev() {
    setPage(page - 1);
  }

  return (
    <ProceduresAll.Provider
      value={{
        limit,
        setLimit,
        data,
        setData,
        page,
        hasNextPage,
        hasPrevPage,
        handleNext,
        handlePrev,
        total,
        showPagination,
      }}
    >
      <div className="">
        <div className="flex justify-between w-[78%] pl-4 pt-8 mt-5 mb-5">
          <h2 className="font-medium text-2xl">Procedure</h2>
        </div>
        <div>
          <Table>
            <TableHeading Titles={Title} />
            <TableDetails data={data} />
            <div className="mt-8">
              <div className="mb-2 text-md font-medium inline-flex gap-x-2 tracking-tight ">
                Conclusion from tests:
                <span>
                  <p className="mb-3 font-light text-black ">
                    Lorem ipsum dolor sit amet
                  </p>
                </span>
              </div>
            </div>
            <Infosection />
          </Table>
        </div>
      </div>
    </ProceduresAll.Provider>
  );
};

export default ProcedureTable;
