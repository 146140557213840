import Steppers from "../../../../../../../Components/Steppers/Steppers";

import {
  FaWpforms,
  FaUserPlus,
} from "react-icons/fa";
const AddSteppers = ({ currentStep, close }) => {
  return (
    <Steppers currentStep={currentStep} close={close}>
      <div className="flex flex-row items-center gap-x-3 font-DM-Bold text-white text-xl">
        <FaWpforms />
        <p>Add Prescription Details</p>
      </div>
      <div className="flex flex-row items-center gap-x-3 font-DM-Bold text-white text-xl">
        <FaUserPlus />
        <p>Choose Medicine</p>
      </div>
    </Steppers>
  );
};

export default AddSteppers;
