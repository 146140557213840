import Steppers from "../../../../../../Components/Steppers/Steppers";

import { FaWpforms, FaUserPlus } from "react-icons/fa";

const AddSteppers = ({ step, close }) => {
  return (
    <Steppers currentStep={step} close={close}>
      <div className="flex flex-row items-center gap-x-3 font-DM-Bold text-white text-xl">
        <FaUserPlus />
        <p>Choose Medicine</p>
      </div>
      <div className="flex flex-row items-center gap-x-3 font-DM-Bold text-white text-xl">
        <FaWpforms />
        <p>Add Prescription Details</p>
      </div>
    </Steppers>
  );
};

export default AddSteppers;
