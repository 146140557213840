import axios from "axios";

import useApp from "./useApp";

import useHandleErrors from "./useHandleErrors";

const baseUrl = "https://api.v2.mkombozihealthcare.co.tz/api";
// const baseUrl = "http://localhost:4432/api";

const useAxios = () => {
  const { setIsLoading, setIsOnline, token } = useApp();

  const error = useHandleErrors();

  return async (obj) => {
    const {
      headers = {},
      method,
      data = {},
      url,
      params = {},
      network_error = true,
      isLoading = true,
    } = obj;
    try {
      if (isLoading) {
        setIsLoading(isLoading);
      }

      let res = await axios({
        headers: { Authorization: `Bearer ${token}`, ...headers },
        method: method,
        url: `${baseUrl}/${url}`,
        data: data,
        params: params,
      });

      setIsOnline(true);
      return res.data;
    } catch (e) {
      error(e, network_error);

      if (network_error === false && e.message === "Network Error") {
        setIsOnline(false);
        if (e.message === "Network Error") return "network-error";
      }

      return "error";
    } finally {
      if (isLoading) {
        setTimeout(() => {
          setIsLoading(false);
        }, 500);
      }
    }
  };
};

export default useAxios;
