import React from "react";
import BasicModal from "../../../../../../../Components/Modals/BasicModal";
import CommentsTable from "../ComentsTable/Table";

const ViewComments = ({ showModal, setShowModal, doc, handleUpdate }) => {
  return (
    <BasicModal showModal={showModal} setShowModal={setShowModal}>
      <div
        className="flex flex-col w-full gap-y-8 p-5"
        style={{ width: "60vw" }}
      >
        <h2 className="font-DM-Reg text-xl text-black text-center">
          Visit Comments
        </h2>
        <h2 className="font-DM-Reg text-xl text-black text-center"></h2>
        <CommentsTable comments={doc} handleUpdate={handleUpdate} />
      </div>
    </BasicModal>
  );
};

export default ViewComments;
