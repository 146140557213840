import React, { useContext, useEffect, useState } from 'react'
import BtnPlain from '../../../../../../../Components/Buttons/BtnPlain'
import useAxios from '../../../../../../../Hooks/useAxios';
import useApp from '../../../../../../../Hooks/useApp';

import usePouchCud from '../../../../../Hooks/usePouchCud';
import { FaRegTrashAlt } from 'react-icons/fa';
import TableContext from './TContext';

const TableActions = ({ doc}) => {

    const request = useAxios()
    const { setShowSuccessModal, setModalMessage } = useApp();
    const {data, setData, comments, handleUpdate } = useContext(TableContext)
    const {deleteDoc} = usePouchCud()
    const [updates, setUpdates] = useState(false)

    const handleDelete = async () => {
        let res = await request({
            method: "DELETE",
            url: `hospital/visit/comment/${doc.id}`
          });
      
          if (res !== "error") {
            
            setModalMessage("Visit Comment Deleted!");
            setShowSuccessModal(true);
            await deleteDoc({name: 'visit_comments', doc:res})
            handleUpdate()
            let newDocs = comments.filter((row) => (row.id === doc.id ? false : true));
            setData(newDocs);
          }

       
        
    }
    useEffect(() => {
         
    }, [data, doc, comments])
    return (
        <div className="flex-1">
            <BtnPlain
                activeBgColor="white"
                bg="white"
                click={handleDelete}
            >
                <div className=" gap-x-2 flex items-center active:opacity-50 cursor-pointer">
                    <p className="  text-red">
                        <FaRegTrashAlt />
                    </p>
                </div>
            </BtnPlain>
        </div>
    )
}

export default TableActions