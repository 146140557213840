import Doctor from "../../../../../Assets/Images/doctor.svg";
import PLab from "./PLab";
import PQueue from "./PQueue";

const PatientsSeen = ({patientsSeen, lab , queue}) => {
  return (
    <div className="bg-primary w-full rounded-2xl flex justify-around py-8  text-white">
      <div className="flex flex-col justify-between">
        <h1 className="text-3xl mb-5 mx-5">
          NO. OF PATIENTS SEEN TODAY:{" "}
          <span className="text-4xl font-bold ml-4">{patientsSeen}</span>
        </h1>

        <div className="flex justify-center gap-x-5 pt-10 flex-wrap gap-y-10">
          <PQueue queue={queue} />
          <PLab lab={lab} />
        </div>
      </div>
      <img src={Doctor} alt="Mkombozi Doctor" className=" mx-2 h-auto lg:w-auto" />
    </div>
  );
};

export default PatientsSeen;
