import React, { useState, useRef, useEffect, useContext } from "react";
import TextInput from "../../../../../../../Components/Inputs/TextInput";
import NumberInput from "../../../../../../../Components/Inputs/NumberInput";
import BtnRounded from "../../../../../../../Components/Buttons/BtnRounded";
import CashContext from "../CashContext";

import useHandleFormError from "../../../../../../../Hooks/useHandleFormError";

const FormSection = ({}) => {
  const input = useRef({ form: {}, err: {} });
  const [showError, setShowError] = useState(0);
  const { prescriptionData, setPrescriptionData, setStep } = useContext(CashContext);
  const formErrors = useHandleFormError();

  const handleSubmit = async () => {
    await setShowError(showError + 1);
    if (formErrors({ input })) return;

    setPrescriptionData(input.current.form);

    setStep((c) => c + 1);
  };
  
  return (
    <div className="w-full">
      <div className="w-full flex gap-x-6">
        <div className=" flex-1">
          <TextInput
            initial={prescriptionData.dosage}
            input={input}
            showError={showError}
            inputKey="dosage"
            mt={0.5}
            config={{
              required: false,
              label: "Dosage",
              placeholder: "2 Tablets",
              type: "text",
              maxChar: 250,
              minChar: 2,
            }}
          />

          <NumberInput
            config={{
              disabled: false,
              label: "No of Days ",
              max: 999999999999,
              min: 1,
              placeholder: "No of Days",
              required: false,
            }}
            mt={0.5}
            showError={showError}
            initial={prescriptionData.no_of_day}
            input={input}
            inputKey="no_of_day"
          />
            <NumberInput
            config={{
              disabled: false,
              label: "Frequency",
              max: 999999999999,
              min: 1,
              placeholder: "Frequency",
              required: false,
            }}
            mt={0.5}
            showError={showError}
            initial={prescriptionData.frequency}
            input={input}
            inputKey="frequency"
          />

          <TextInput
            initial={prescriptionData.food_relation}
            input={input}
            showError={showError}
            inputKey="food_relation"
            mt={0.5}
            config={{
              required: false,
              label: "Food Relation",
              placeholder: "Eg. Breakfast Lunch and Supper",
              type: "text",
              inputType: "food_relation",
              maxChar: 250,
              minChar: 4,
            }}
          />

          <TextInput
            initial={prescriptionData.comments}
            input={input}
            showError={showError}
            inputKey="comments"
            mt={0.5}
            config={{
              required: false,
              label: "Comments",
              placeholder: "Comments",
              type: "comments",
              maxChar: 250,
              minChar: 2,
            }}
          />
         
        </div>
      </div>
      <BtnRounded mt={2} click={handleSubmit} className="btn">
        <span className=" font-pop-reg">Continue</span>
      </BtnRounded>
    </div>
  );
};

export default FormSection;
