import { useContext } from "react";

import TableInfoRow from "../../../../../../../../Components/FinalTable/components/TableInfoRow";
import { TableInfoCell } from "../../../../../../../../Components/FinalTable";
import { TablePagination } from "../../../../../../../../Components/FinalTable";

import TableContext from "../../context/PrescriptionsContext"

const Infosection =()=>{
    const {
        limit,
        setLimit,
        total,
        data,
        page,
        hasNextPage,
        hasPrevPage,
        handleNext,
        handlePrev,
        showPagination,
      } = useContext(TableContext);
      return (
        <TableInfoRow mt={20}>
          <TableInfoCell
            limit={limit}
            setLimit={setLimit}
            total={total}
            docs={data}
          />
          {showPagination ? (
            <TablePagination
              page={page}
              hasNextPage={hasNextPage}
              hasPrevPage={hasPrevPage}
              nextClick={handleNext}
              prevClick={handlePrev}
            />
          ) : null}
        </TableInfoRow>
      )
};

export default Infosection;