import { TableRow, TableCell, TableText } from "../../../../../../../../Components/FinalTable";
import { convDate2 } from "../../../../../../../../Util/Time";


const TableDetails=({ data})=>{
    return(
        <div>
            { data?.map((docs, i)=>(
                 <TableRow mt={5} key={i} >
                     <TableCell w={100 / 4}>
                         <TableText label={
                            convDate2(docs.created_at).replaceAll("-","/")
                            }/>
                     </TableCell>
                     <TableCell w={100 / 4}>
                         <TableText label={docs.team.name}/>
                     </TableCell>
                     <TableCell w={100 / 4}>
                         <TableText label={docs.diagnosis}/>
                     </TableCell>
                 </TableRow>
             

            ))}
        </div>
    )
};

export default TableDetails;
