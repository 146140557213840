import React, { useContext, useEffect, useState } from "react";

import {
  Numeric,
  TextInput,
  TextArea,
} from "../../../../../../Components/Inputs/StateInput";

import PrescriptionV2Context from "../PrescriptionV2Context";

import BtnRounded from "../../../../../../Components/Buttons/BtnRounded";

import useAxios from "../../../../../../Hooks/useAxios";
import useApp from "../../../../../../Hooks/useApp";

import { useParams } from "react-router-dom";

import FormBackBtn from "../../../../../../Components/Updated/FormBackBtn";

import { useNavigate } from "react-router-dom";

const FormSection = () => {
  const [dosage, setDosage] = useState(1);
  const [frequency, setFrequency] = useState(1);
  const [days, setDays] = useState(1);
  const [food_relation, setFoodRelation] = useState("");
  const [comments, setComments] = useState("");
  const [qty, setQty] = useState(1);

  const { consumable, prescription, setPrescription, handleBack } = useContext(
    PrescriptionV2Context
  );

  const { user, setShowFormModal, setModalMessage, setShowSuccessModal } =
    useApp();
  const request = useAxios();

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (prescription.dosage) {
      setDosage(prescription.dosage);
    }

    if (prescription.days) {
      setDays(prescription.days);
    }

    if (prescription.frequency) {
      setFrequency(prescription.frequency);
    }
    if (prescription.food_relation) {
      setFoodRelation(prescription.food_relation);
    }
    if (prescription.qty) {
      setQty(prescription.qty);
    }
    if (prescription.comments) {
      setComments(prescription.comments);
    }
  }, []);

  useEffect(() => {
    if (dosage === "" || frequency === "" || days === "") {
      return;
    }
    setQty(parseInt(dosage) * parseInt(frequency) * parseInt(days));
  }, [dosage, frequency, days]);

  async function handleSubmit() {
    let data = {
      dosage: dosage || "",
      no_of_day: parseInt(days) || 1,
      frequency: parseInt(frequency) || 1,
      food_relation: food_relation || "",
      qty: qty,
      visit_step_id: parseInt(id),
      requested_by: parseInt(user.id),
      consumable_id: parseInt(consumable.id),
    };

    let res = await request({
      method: "POST",
      url: "hospital/visit/prescription",
      data: data,
    });

    if (res === "error") return;

    setModalMessage("Prescription Added");
    setShowSuccessModal(true);
    navigate(`/dashboard/attend/${id}`);
    return;
  }

  function goBack() {
    setPrescription({
      dosage,
      frequency,
      days,
      food_relation,
      comments,
      qty,
    });
    handleBack();
  }

  return (
    <>
      <div className="w-full  flex flex-1 items-center justify-center ">
        <div
          style={{ width: "40%" }}
          className=" bg-ghost_white p-6 rounded-2xl"
        >
          <TextInput
            label="Selected Consumable"
            disabled={true}
            input={consumable.inventory_items.name}
          />
          <div className="flex gap-x-4 w-full">
            <Numeric
              label="Dosage *"
              mt={1}
              input={dosage}
              setInput={setDosage}
            />
            <Numeric
              placeholder="2"
              label="Frequency *"
              mt={1}
              input={frequency}
              setInput={setFrequency}
            />
          </div>
          <Numeric
            placeholder="2"
            label="No Of Days *"
            mt={1}
            input={days}
            setInput={setDays}
          />
          <TextInput
            mt={1}
            label="Food Relation"
            input={food_relation}
            setInput={setFoodRelation}
            required={false}
          />
          <Numeric
            placeholder="2"
            label="Total Qty"
            mt={1}
            input={qty}
            setInput={setQty}
          />
          <TextArea
            placeholder="Doctor Comments ... "
            label="Comments"
            mt={1}
            input={comments}
            setInput={setComments}
          />
          <BtnRounded mt={2} click={handleSubmit} className="btn">
            <span className=" font-pop-reg">Continue</span>
          </BtnRounded>
        </div>
      </div>
      <FormBackBtn click={goBack} />
    </>
  );
};

export default FormSection;
