import { useContext, useEffect, useState } from "react";

import { Table } from "../../../../../../../../Components/FinalTable";

import THeader from "./THeader";

import TBody from "./TBody";

import TInfo from "./TInfo";

import TContext from "./TContext";

import usePouchPagination from "../../../../../../../../Hooks/usePouchPagination";

import { TableTopBar } from "../../../../../../../../Components/FinalTable";

import useFuzzySearch from "../../../../../../../../Hooks/useFuzzySearch";
import BtnRounded from "../../../../../../../../Components/Buttons/BtnRounded";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../../../../../../Hooks/useAxios";
import useApp from "../../../../../../../../Hooks/useApp";
import useFeatures from "../../../../../../Hooks/useFeatures";
import usePouchCud from "../../../../../../Hooks/usePouchCud";
import CashContext from "../../CashContext";
import Fuse from "fuse.js";

const PouchData = ({ showLive, setShowLive }) => {
  const [limit, setLimit] = useState(10);
  const [data, setData] = useState([]);
  const [checked_id, setCheckedId] = useState(-1);
  const [showPagination, setShowPagination] = useState(true);
  const [sortOrder, setSordOrder] = useState("desc");
  const [sortField, setSortField] = useState("created_at");
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPrevPage, setHasPrevPage] = useState(false);
  const [page, setPage] = useState(1);
  const { id } = useParams();
  const { create } = usePouchCud();
  const { prescriptionData } = useContext(CashContext);
  const [tableIsLoading, setTableIsLoading] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [visitId, setVisitId] = useState(0);

  const { setShowFormModal, setModalMessage, setShowSuccessModal, user } =
    useApp();

  const [docs, setDocs] = useState(0);

  const request = useAxios();

  const navigate = useNavigate();

  useEffect(() => {
    getInitConsumables(1, "created_at", "desc");
  }, [limit]);

  useEffect(() => {
    if (searchInput.length < 3) return;
    async function search() {
      setTableIsLoading(true);
      let res = await request({
        method: "GET",
        url: "hospital/consumables/search",
        params: {
          value: searchInput,
        },
      });
      // console.log(res);
      if (res === "network-error" || res === "error") {
        navigate("/dashboard/user-queue");
        return;
      }
      setShowLive(true);

      setData(res);

      setShowPagination(false);
      setIsFiltered(true);
      setTableIsLoading(false);
    }

    search();
  }, [searchInput]);

  async function getInitConsumables(
    page = 1,
    sort_field = "created_at",
    sort_order = "desc"
  ) {
    setTableIsLoading(true);
    let res = await request({
      method: "GET",
      url: "hospital/consumables",
      params: {
        page,
        limit,
        sort_field: sort_field,
        sort_order: sort_order,
      },
      network_error: false,
    });

    if (res === "network-error" || res === "error") {
      navigate("/dashboard/user-queue");
      return;
    }

    setTableIsLoading(false);

    setData(res.docs);
    setHasNextPage(res.hasNextPage);
    setHasPrevPage(res.hasPrevPage);
  }

  async function handleNext() {
    if (hasNextPage) {
      setPage(page + 1);
      getInitConsumables(page + 1, sortField, sortOrder);
    }
  }

  async function handlePrev() {
    if (hasPrevPage) {
      setPage(page - 1);
      getInitConsumables(page - 1, sortField, sortOrder);
    }
  }

  async function handleFilterQuery(q) {
    //console.log(q);
  }

  async function handleHeaderQuery(field, order) {
    setPage(1);
    setSordOrder(order);
    setSortField(field);
    getInitConsumables(1, field, order);
  }

  async function handleClearFilters() {
    await getInitConsumables(1, "created_at", "desc");
    setPage(1);
    setSortField("created_at");
    setSordOrder("desc");
    setIsFiltered(false);
    setShowPagination(true);
    setSearchInput("");
  }

  useEffect(() => {
    async function Initialize() {
      let res = await request({
        method: "GET",
        url: `hospital/records/consultation?visit_step_id=${id}`,
      });

      if (res === "error" || res === "error") {
        navigate("/dashboard/user-queue");
        return;
      }

      setVisitId(res.visit.id);
    }

    Initialize();
  }, [id]);

  async function Submit() {
    if (docs.length === 0) {
      setModalMessage("Select at least one Consumable");
      setShowFormModal(true);
      return;
    }
    let res = await request({
      method: "POST",
      url: "hospital/visit/prescription",
      data: {
        dosage: prescriptionData.dosage,
        no_of_day: parseInt(prescriptionData.no_of_day),
        frequency: parseInt(prescriptionData.frequency),
        food_relation: prescriptionData.food_relation,
        visit_id: parseInt(visitId),
        consumable_id: parseInt(checked_id),
        requested_by: parseInt(user.id),
      },
    });

    if (res !== "error") {
      setModalMessage("Prescription Added");
      setShowSuccessModal(true);
      await create({ name: "visit_prescription", doc: res });
      navigate(`/dashboard/attend/${id}`);
    }
  }

  return (
    <TContext.Provider
      value={{
        showLive,
        limit,
        setLimit,
        data,
        page,
        hasNextPage,
        hasPrevPage,
        handleNext,
        handlePrev,
        handleHeaderQuery,
        handleFilterQuery,
        isFiltered,
        handleClearFilters: handleClearFilters,
        total: "...",
        setIsFiltered,
        showPagination,
        resetQuery: handleClearFilters,
        checked_id,
        setCheckedId,
        docs,
        setDocs,
      }}
    >
      <div className="px-7 pb-8">
        <TableTopBar
          mt={2}
          mb={2}
          showAdd={false}
          // AddClick={handleAdd}
          changes={0}
          handleLiveStream={() => {}}
          isFiltered={isFiltered}
          handleClearFilters={handleClearFilters}
          tableIsLoading={tableIsLoading}
          showReport={false}
          showFilter={false}
          // addLabel="Add"
          searchInput={searchInput}
          setSearchInput={setSearchInput}
        />
        <Table>
          <THeader />
          <TBody />
          <TInfo />
          <div className="max-w-2xl w-full mx-auto">
            <BtnRounded mt={2} click={Submit} className="btn">
              <span className=" font-pop-reg">Add Prescription</span>
              <span className="px-3 font-normal text-2xl"></span>
            </BtnRounded>
          </div>
        </Table>
      </div>
    </TContext.Provider>
  );
};

export default PouchData;
