import React from 'react'
import VisitsChart from './VisitsChart'

const Visits = ({wVisits}) => {
  return (
    <div className='w-full px-4 h-96 bg-white rounded-2xl flex flex-col '>
      <div className="flex justify-between items-center align-middle">
        <h1 className="font-bold text-lg px-6 py-3">Visits this week</h1>
      </div>
      <hr />
      <div className='' >
        <VisitsChart wVisits={wVisits} />
      </div>
    </div>
  )
}

export default Visits