import { useRef, useState, useContext } from "react";
import TextInput from "../../../../../../Components/Inputs/TextInput";

import SelectInput from "../../../../../../Components/Inputs/SelectInput";

import DateSelector from "../../../../../../Components/Inputs/DatePicker";

import BtnRounded from "../../../../../../Components/Buttons/BtnRounded";

import { convDate2 } from "./../../../../../../Util/Time";

import AddRequestContext from "../context/AddRequestContext";

const ItemDetails = () => {
  const { setStep, setItemType } = useContext(AddRequestContext);

  const input = useRef({
    form: {
      type: "inv",
    },
    err: {},
  });

  async function submit() {
    if (input.current.form.type === "inv") {
      setItemType("inv");
      setStep(1);
      return;
    }
    setItemType("fixed");
    setStep(1);
  }

  return (
    <div className=" w-full flex justify-center">
      <div className="w-[587px]  bg-white shadow-md rounded-lg p-5 mt-5 ">
        <SelectInput
          config={{
            disabled: false,
            firstDisabled: false,

            items: [
              {
                value: "inv",
                label: "Inventory Items",
              },
              {
                value: "fixed",
                label: "Fixed Assets",
              },
            ],
            label: "Item type",
            required: false,
          }}
          initial="inv"
          input={input}
          inputKey="type"
        />

        <TextInput
          config={{
            disabled: true,
            label: "Location",
            maxChar: 200,
            minChar: 3,
            placeholder: "Reception",
            required: true,
            type: "text",
          }}
          input={input}
          initial={"Consultation"}
          inputKey="location"
        />

        <DateSelector
          config={{
            disabled: true,
            label: "Request Date",
            max: undefined,
            min: undefined,
            required: true,
          }}
          initial={convDate2(Date.now())}
          input={input}
          inputKey="date"
        />

        <BtnRounded click={submit}>Continue</BtnRounded>
      </div>
    </div>
  );
};

export default ItemDetails;
