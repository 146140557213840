import React from 'react'
import DoctorQueue from './QueueTable'
import { useNavigate } from 'react-router-dom'

const Queue = ({userQueue}) => {
    const navigate = useNavigate()
    return (
        <div className='w-12/12 bg-white rounded-2xl p-6 '>
            <div className="flex justify-between">
                <h1 className="text-lg font-bold px-6">Queue</h1>
                <div className="bg-none text-sky-600 rounded-lg px-6 py-2 cursor-pointer underline" onClick={() => navigate('user-queue')} >
          Go to Queue
        </div>
            </div>
           <DoctorQueue userQueue={userQueue} />
        </div>
    )
}

export default Queue