import React, { useEffect, useState } from "react";
import CartModal from "../../../../../../Components/Updated/CartModal";
import CartCard from "./CartCard";

const InvCart = ({
  showCart,
  setShowCart,
  cartData = {},
  setCartData = () => {},
  add
}) => {
  
  const [docs, setDocs] = useState([]);

  useEffect(() => {
    let keys = Object.keys(cartData);

    let ar = [];

    for (let key of keys) {
      ar.unshift(cartData[key]);
    }
    setDocs(ar);
  }, [cartData]);

  return (
    <CartModal showCart={showCart} setShowCart={setShowCart} add={add}>
      {docs.map((doc) => (
        <CartCard key={doc.id} doc={doc} setCartData={setCartData} />
      ))}
    </CartModal>
  );
};

export default InvCart;
