import React, { useContext, useEffect, useState } from "react";

import {
  Table,
  TableTopBar,
  TableInfoRow,
  TableInfoCell,
  TablePagination,
} from "../../../../../../../Components/FinalTable";

import AddPrescriptionsV2Context from "./../../PrescriptionV2Context";
import { navigate } from "@storybook/addon-links";

import { useParams } from "react-router-dom";
import useAxios from "../../../../../../../Hooks/useAxios";

import THeader from "./THeader";

import TBody from "./TBody";

import BtnRounded from "../../../../../../../Components/Buttons/BtnRounded";

import useApp from "../../../../../../../Hooks/useApp";

import FormBackBtn from "../../../../../../../Components/Updated/FormBackBtn";

const ConsumableTable = () => {
  const [limit, setLimit] = useState(10);
  const [data, setData] = useState([]);
  const [showPagination, setShowPagination] = useState(true);
  const [sortOrder, setSordOrder] = useState("desc");
  const [sortField, setSortField] = useState("created_at");
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPrevPage, setHasPrevPage] = useState(false);
  const [page, setPage] = useState(1);
  const [tableIsLoading, setTableIsLoading] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  const {
    checkedConsumable,
    setCheckedConsumable,
    setConsumable,
    setStep,
    handleBack,
  } = useContext(AddPrescriptionsV2Context);

  const request = useAxios();

  const { setShowFormModal, setModalMessage } = useApp();

  async function getInitConsumables(
    page = 1,
    sort_field = "created_at",
    sort_order = "desc"
  ) {
    setTableIsLoading(true);
    let res = await request({
      method: "GET",
      url: "hospital/consumables",
      params: {
        page,
        limit,
        sort_field: sort_field,
        sort_order: sort_order,
      },
    });

    if (res == "error") {
      navigate(-1);
      return;
    }
    setData(res.docs);
    setHasNextPage(res.hasNextPage);
    setHasPrevPage(res.hasPrevPage);
    setTableIsLoading(false);
  }

  async function handleNext() {
    if (hasNextPage) {
      setPage(page + 1);
      getInitConsumables(page + 1, sortField, sortOrder);
    }
  }

  async function handlePrev() {
    if (hasPrevPage) {
      setPage(page - 1);
      getInitConsumables(page - 1, sortField, sortOrder);
    }
  }

  async function handleClearFilters() {
    await getInitConsumables(1, "created_at", "desc");
    setPage(1);
    setSortField("created_at");
    setSordOrder("desc");
    setIsFiltered(false);
    setShowPagination(true);
    setSearchInput("");
  }

  async function submit() {
    if (checkedConsumable == -1) {
      setModalMessage("Select a consumable");
      setShowFormModal(true);
      return;
    }
    setStep((c) => c + 1);
  }

  useEffect(() => {
    getInitConsumables();
  }, [limit]);

  useEffect(() => {
    if (searchInput.length < 3) return;
    async function search() {
      setTableIsLoading(true);
      let res = await request({
        method: "GET",
        url: "hospital/consumables/search",
        params: {
          value: searchInput,
        },
      });
      // console.log(res);
      if (res === "network-error" || res === "error") {
        navigate("/dashboard/user-queue");
        return;
      }

      setData(res);

      setShowPagination(false);
      setIsFiltered(true);
      setTableIsLoading(false);
    }

    search();
  }, [searchInput]);

  return (
    <div className=" mx-16">
      <TableTopBar
        mt={2}
        mb={2}
        isFiltered={isFiltered}
        showFilter={false}
        handleClearFilters={handleClearFilters}
        tableIsLoading={tableIsLoading}
        searchInput={searchInput}
        setSearchInput={setSearchInput}
      />
      <Table>
        <THeader />
        <TBody
          checkedConsumable={checkedConsumable}
          setCheckedConsumable={setCheckedConsumable}
          setConsumable={setConsumable}
          data={data}
        />
        <TableInfoRow mt={20}>
          <TableInfoCell
            limit={limit}
            setLimit={setLimit}
            total={""}
            docs={data}
          />
          <TablePagination
            page={page}
            hasNextPage={hasNextPage}
            hasPrevPage={hasPrevPage}
            nextClick={handleNext}
            prevClick={handlePrev}
          />
        </TableInfoRow>
        <div className="flex justify-center">
          <BtnRounded mt={2} click={submit} className="btn">
            <span className=" font-pop-reg text-xl">Add Consumable</span>
          </BtnRounded>
        </div>
      </Table>{" "}
      <FormBackBtn click={handleBack} />
    </div>
  );
};

export default ConsumableTable;
