import React, { useContext, useState } from "react";
import BtnPlain from "../../../../../../Components/Buttons/BtnPlain";
import useAxios from "../../../../../../Hooks/useAxios";
import useApp from "../../../../../../Hooks/useApp";

import { FaRegTrashAlt, FaEdit, FaRegEye } from "react-icons/fa";
import TableContext from "./TContext";

import OtherNotes from "../Modals/OtherNotes";

const TableActions = ({ doc }) => {
  const request = useAxios();
  const { setShowSuccessModal, setModalMessage } = useApp();
  const { handleUpdate } = useContext(TableContext);
  const [showNotes, setShowNotes] = useState(false);

  async function handleDelete() {
    let res = await request({
      method: "DELETE",
      url: `hospital/note-v2/${doc.id}`,
    });
    if (res === "error") return;

    setModalMessage("Visit Note Deleted!");
    setShowSuccessModal(true);
    handleUpdate();
  }

  function handleEdit() {
    setShowNotes(true);
  }

  return (
    <div className="flex-1 flex flex-row gap-x-3">
      <BtnPlain activeBgColor="white" bg="white" click={handleDelete}>
        <div className=" gap-x-2 flex items-center active:opacity-50 cursor-pointer">
          <p className="  text-red text-2xl">
            <FaRegTrashAlt />
          </p>
        </div>
      </BtnPlain>
      <BtnPlain activeBgColor="white" bg="white" click={handleEdit}>
        <div className=" gap-x-2 flex items-center active:opacity-50 cursor-pointer">
          <p className=" text-primary text-2xl">
            <FaEdit />
          </p>
        </div>
      </BtnPlain>
      {showNotes && (
        <OtherNotes
          showModal={showNotes}
          setShowModal={setShowNotes}
          handleUpdate={handleUpdate}
          n={doc}
        />
      )}
    </div>
  );
};

export default TableActions;
