import React from "react";

import HeaderBtn from "../../../../../../Components/FinalTable/components/Btns/HeaderBtn";

import { TableHeaderRow } from "../../../../../../Components/FinalTable";

const THead = () => {
  return (
    <div className="w-full">
      <TableHeaderRow>
        <HeaderBtn w={90 / 3} label="ID" />
        <HeaderBtn w={90 / 3} label="CREATED BY" />
        <HeaderBtn w={90 / 3} label="DATE" />
        <HeaderBtn w={10} label="ACTIONS" />
      </TableHeaderRow>
    </div>
  );
};

export default THead;
