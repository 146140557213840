import { convDate2 } from "../../../../../Util/Time";

const Details = ({ patient, condition }) => {
  const { gender, phone, occupation, email, dob } = patient;
  return (
    <div>
      <div className="space-y-4 pb-14">
        <h2 className="font-medium text-2xl">Patient Details</h2>
        <div className="flex justify-start">
          <div className="grid grid-cols-2 gap-8">
            <div>
              <h5 className="mb-2 text-xl font-medium tracking-tight text-cadet_grey ">
                GENDER
              </h5>
              <p className="mb-3 font-normal text-gray-700 ">{gender}</p>
            </div>
            <div>
              <h5 className="mb-2 text-xl font-medium tracking-tight text-cadet_grey ">
                PHONE
              </h5>
              <p className="mb-3 font-normal text-gray-700 ">{phone}</p>
            </div>
            <div>
              <h5 className="mb-2 text-xl font-medium tracking-tight text-cadet_grey ">
                EMAIL
              </h5>
              <p className="mb-3 font-normal text-gray-700 ">{email}</p>
            </div>
            <div>
              <h5 className="mb-2 text-xl font-medium tracking-tight text-cadet_grey ">
                OCCUPATION
              </h5>
              <p className="mb-3 font-normal text-gray-700 ">{occupation}</p>
            </div>
            <div>
              <h5 className="mb-2 text-xl font-medium tracking-tight text-cadet_grey ">
                DATE OF BIRTH
              </h5>
              <p className="mb-3 font-normal text-gray-700 ">
                {convDate2(new Date(parseInt(dob))).replaceAll("-", "/")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Details;
