import TableHeader from '../../../../../../../../Components/FinalTable/components/TableHeader';

import HeaderBtnSort from '../../../../../../../../Components/FinalTable/components/Btns/HeaderBtnSort';

const TableHeading =({Titles})=>{
    return (
        <div>
            <TableHeader h={2} mt={10}>
                { Titles.map((title)=>(
                       <HeaderBtnSort
                       click={function noRefCheck(){}}
                       label={title.name}
                       key={title.id}
                       w={100 / 6}
                   />
                ))
                }
            </TableHeader>
        </div>
    )
}
export default TableHeading;