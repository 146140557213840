import React, { useState, useEffect } from "react";

import { colors } from "../../../../../../Util/colors";

const MultiSelect = ({
  doc,
  listData = {},
  setListData = () => {},
  mt = 0,
  mb = 0,
}) => {
  const [borderColor, setBorderColor] = useState("bright_grey");
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    let keys = Object.keys(listData);

    for (let key of keys) {
      if (doc.id === listData[key].id) {
        setChecked(true);
        return;
      }
    }
    setChecked(false);
  }, [listData]);

  const handleChange = () => {
    setChecked(!checked)
    let newListData = { ...listData };
    if (checked) {
      if (listData[doc.id]) {
        delete newListData[doc.id];
        setListData(newListData);
      }
      return;
    }

    newListData[doc.id] = doc;
    setListData(newListData);
  };

 

  return (
    <div
      className="flex flex-col px-2"
      style={{
        marginTop: `${mt}em`,
        marginBottom: `${mb}em`,
      }}
    >
      <input
        className={`border-2 rounded-lg h-5 w-5 cursor-pointer checked:span>text-primary placeholder-cadet_grey `}
        onFocus={() => setBorderColor("primary")}
        style={{
          borderColor: colors[borderColor],
        }}
        type="checkbox"
        checked={checked}
        onChange={handleChange}
      />
    </div>
  );
};

export default MultiSelect;
