import ConsumableCsh from "./ConsumableTable";
import Prescription from "./PrescriptionDetails";

const Forms = ({ step }) => {
  if (step === -1) {
    return <Prescription />;
  }

  if (step === 0) {
    return <ConsumableCsh />;
  }

  return null;
};

export default Forms;
