import React, { useEffect, useState } from "react";
import { colors } from "./../../Util/colors";

const BtnBlock = ({
  bg = "primary",
  bgActive = "primary_accent",
  textColor = "white",
  mt = 0,
  mb = 0,
  click,
  children,
}) => {
  const [bgColor, setBgColor] = useState(bg);

  useEffect(() => {
    setBgColor(bg);
  }, [bg]);

  function handleMouseDown() {
    setBgColor(bgActive);
  }

  function handleMouseUp() {
    setBgColor(bg);
  }

  return (
    <div
      className={`rounded-md cursor-pointer flex flex-row justify-center items-center w-full px-2 py-1   font-DM-Bold text-base`}
      onClick={click}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      style={{
        backgroundColor: colors[bgColor],
        color: colors[textColor],
        marginTop: `${mt}em`,
        marginBottom: `${mb}em`,
      }}
    >
      {children}
    </div>
  );
};

export default BtnBlock;
