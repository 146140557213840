import React, { useState } from "react";

import CashContext from "./CashContext";

import { FaUsers } from "react-icons/fa";

import FormScreen from "../../../../../../Components/Templates/FormScreen";

import FormLabel from "../../../../../../Components/Label/FormLabel";

import Steppers from "./Components/Steppers";

import Forms from "./Components/Forms";

import { useNavigate } from "react-router-dom";

const ConsumableCashAdd = () => {
  const [step, setStep] = useState(-1);
  const [selectedConsumable, setselectedConsumable] = useState(0);
  const [prescriptionData, setPrescriptionData] = useState({
    dosage: "",
    no_of_day: "0",
    frequency: "",
    food_relation: "",
    comment: "",
  });

  const navigate = useNavigate();

  function handleBack() {
    //console.log("handle back");
    if (step === -1) return;
    setStep((c) => c - 1);
  }

  return (
    <CashContext.Provider
      value={{
        prescriptionData,
        setPrescriptionData,
        selectedConsumable,
        setselectedConsumable,
        step,
        setStep,
      }}
    >
      <FormScreen backBtn={true} back={handleBack}>
        <FormLabel label="Add Prescription" mt={2} mb={2}>
          <FaUsers />
        </FormLabel>
        <Steppers currentStep={step} close={() => navigate(-1)} />
        <div className="w-full flex flex-row justify-center items-center">
          <div className=" shadow-lg p-6 rounded-2xl" style={{ width: "60%" }}>
            <Forms step={step} setStep={setStep} />
          </div>
        </div>
      </FormScreen>
    </CashContext.Provider>
  );
};

export default ConsumableCashAdd;
