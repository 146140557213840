import TRow from "./TRow";

const TBody = ({
  checkedConsumable,
  setCheckedConsumable,
  data,
  setConsumable,
}) => {
  return (
    <>
      {data.map((doc) => (
        <TRow
          key={doc.id || doc._id}
          doc={doc}
          checkedConsumable={checkedConsumable}
          setCheckedConsumable={setCheckedConsumable}
          setConsumable={setConsumable}
        />
      ))}
    </>
  );
};

export default TBody;
