import React from "react";
import { HiXMark } from "react-icons/hi2";

const PresList = ({ setShowList, children, add }) => {
  return (
    <div className=" h-full flex w-full justify-end ">
      <div className=" w-fit bg-white h-full overflow-y-auto flex flex-col ">
        <ListTopBar setShowList={setShowList} />
        <div className="px-6 py-6">{children}</div>
        <div className="flex-1"></div>
        {/* <div
          onClick={() => add}
          className="cursor-pointer w-full flex justify-center items-center px-8 py-2 bg-primary font-DM-Bold text-base text-white active:opacity-20"
        >
          Add Consumable
        </div> */}
      </div>
    </div>
  );
};

const ListTopBar = ({ setShowList }) => {
  return (
    <div
      className=" flex justify-between items-center px-6 mt-4"
      style={{ minWidth: "320px" }}
    >
      <p className="  text-primary text-2xl tracking-wider">Prescriptions</p>
      <p
        onClick={() => setShowList(false)}
        className=" text-3xl active:opacity-20   text-black cursor-pointer active:text-primary"
      >
        <HiXMark />
      </p>
    </div>
  );
};

export default PresList;
