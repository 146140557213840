import PatientDetails from "../../../Patients/View/components/PatientDetails";

import BasicModal from '../../../../../../Components/Modals/BasicModal'

const ViewPatient = ({ patient, visit, showModal, setShowModal }) => {
    return (
      <BasicModal showModal={showModal} setShowModal={setShowModal}>
        <p className="flex w-full justify-center text-2xl font-DM-Bold text-cadet_grey">
          Patient Profile
        </p>
        <div
          style={{ width: "60em" }}
          className=" flex items-center justify-center pb-7"
        >
          <PatientDetails patient={patient} />
        </div>
      </BasicModal>
    );
  };
  
  export default ViewPatient;