const BasicDropDown = ({ children, isVisible = true }) => {
  if (isVisible === false) {
    return null;
  }
  return (
    <div
      style={{ zIndex: 3000 }}
      className="absolute  bg-white divide-y divide-gray-100 rounded-lg shadow "
    >
      {children}
    </div>
  );
};

export default BasicDropDown;
