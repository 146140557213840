import Details from "./components/Details";
import AllergiesTable from "./components/AllergiesTable/components/Table";
import DiagnosisTable from "./components/DiagnosisTable/components/Table";
import InvestigationTable from "./components/InvestigationTable/components/Table";
import PrescriptionTable from "./components/PrescriptionTable/components/Table";
import ProcedureTable from "./components/ProcedureTable/components/Table";

import BellTopBar from "../../../../Components/Bars/BellTopBar";
import CancelBar from "../../../../Components/Steppers/CancelBar";

import useApp from "../../../../Hooks/useApp";

import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import useAxios from "../../../../Hooks/useAxios";

const MedicalHistory = () => {
  const [patient, setPatient] = useState({
    gender: "",
    phone: "",
    occupation: "",
    email: "",
    dob: "000000000",
  });

  const [diagnoses, setDiagnoses] = useState("");

  const { setIsLoading } = useApp();

  const { id } = useParams();

  const navigate = useNavigate();

  const request = useAxios();

  useEffect(() => {
    async function initialize() {
      setIsLoading(true);
      let patient = await request({
        url: `patient/${id}`,
      });

      let diagnosis = await request({
        url: `patient/history?patient_id=${id}&type=visit_notes&page=1&limit=1`,
      });

      if ( patient === "error" || diagnosis === "error"){
        navigate("/dashboard/user-queue");
        return;
      }
      
      setDiagnoses(diagnosis.docs[0]?.diagnosis);
      
      setPatient(patient.patient);
      setIsLoading(false);
    }

    initialize();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="w-full bg-ghost_white pb-4">
      <BellTopBar label={(patient.first_name || "") + "- Medical History"} />
      <div className="mt-8 mb-8">
        <CancelBar />
      </div>
      <div className="ml-5 mt-5 p-5 px-8 mb-4 mr-5 bg-white rounded-lg shadow-md ">
        <Details patient={patient} condition={diagnoses} />

        <DiagnosisTable id={id} />
        <AllergiesTable id={id} />
        <InvestigationTable id={id} />
        <ProcedureTable id={id} />
        <PrescriptionTable id={id} />
      </div>
    </div>
  );
};

export default MedicalHistory;
