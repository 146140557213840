import React from "react";

import {
  TableCell,
  TableText,
  TableRow,
} from "../../../../../../Components/FinalTable";

import TableActions from "./TableActions";

import { convDate } from "../../../../../../Util/Time";

const TRow = ({ doc }) => {
  return (
    <TableRow>
      <TableCell w={90 / 3}>
        <TableText label={doc.id} />
      </TableCell>
      <TableCell w={90 / 3}>
        <TableText label={doc.team.name} />
      </TableCell>
      <TableCell w={90 / 3}>
        <TableText label={convDate(doc.created_at)} />
      </TableCell>
      <TableCell w={10}>
        <TableActions doc={doc} />
      </TableCell>
    </TableRow>
  );
};

export default TRow;
