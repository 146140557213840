import React, { useContext } from "react";

import {
  TableCell,
  TableRow,
  TableText,
} from "./../../../../../../../Components/FinalTable";
import MultiSelect from "../../components/MultiSelect";
import MultiPrescriptionCOntext from "../../MultiPrescriptionContext";

const TRow = ({ doc }) => {
  const { listData, setListData } = useContext(MultiPrescriptionCOntext);

  return (
    <TableRow>
      <TableCell w={100 / 6}>
        <TableText label={doc.inventory_items.code} />
      </TableCell>
      <TableCell w={100 / 6}>
        <TableText label={doc.inventory_items.name} />
      </TableCell>
      <TableCell w={100 / 6}>
        <TableText label={doc.inventory_items.unit_of_measures.name} />
      </TableCell>
      <TableCell w={100 / 6}>
        <TableText label={doc.inventory_items.store_qty} />
      </TableCell>
      <TableCell w={100 / 6}>
        <TableText label={doc.inventory_items.unit_cost} />
      </TableCell>
      <TableCell w={100 / 6}>
        <MultiSelect doc={doc} listData={listData} setListData={setListData} />
      </TableCell>
    </TableRow>
  );
};

export default TRow;
