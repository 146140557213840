import React from "react";

import HeaderBtnSort from "./../../../../../../../Components/FinalTable/components/Btns/HeaderBtnSort";

import {
  HorizontalActionBtn,
  TableHeaderRow,
} from "./../../../../../../../Components/FinalTable";
import HeaderBtn from "./../../../../../../../Components/FinalTable/components/Btns/HeaderBtn";

const THeader = () => {
  return (
    <TableHeaderRow>
      <HeaderBtnSort w={100 / 6} label="CODE" />
      <HeaderBtnSort w={100 / 6} label="NAME" />
      <HeaderBtnSort w={100 / 6} label="UNIT OT MEASURE" />
      <HeaderBtnSort w={100 / 6} label="STORE QUANTITY" />
      <HeaderBtnSort w={100 / 6} label="PRICE" />
      <HeaderBtn w={100 / 6} label="Select" />
    </TableHeaderRow>
  );
};

export default THeader;
