import { useEffect, useState } from "react";
import Navbar from "./components/Navbar";
import PatientsChart from "./components/PatientsChart";
import PatientsSeen from "./components/PatientsSeen";
import Queue from "./components/Queue";
import RecentRequests from "./components/RecentRequests";
import TotalRequests from "./components/TotalRequests";
import Visits from "./components/Visits";
import useAxios from "../../../../Hooks/useAxios";
import useApp from "../../../../Hooks/useApp";

const Summary = () => {
  const [recentRequests, setRecentRequests] = useState([]);
  const [mVisits, setMVisits] = useState([]);
  const [totalRequests, setTotalRequests] = useState(0);
  const [patientsSeen, setPatientsSeen] = useState(0);
  const [queue, setQueue] = useState(0);
  const [userQueue, setUserQueue] = useState([]);
  const [lab, setLab] = useState(0);
  const [wVisits, setWVisits] = useState([]);
  const { user } = useApp();
  const [loading, setLoading] = useState(false);
  const request = useAxios();

  async function getInitialData() {
    try {
      setLoading(true);
      let lab = await request({
        method: "GET",
        url: "hospital/analytics/reception",
      });

      let res = await request({
        method: "GET",
        url: "hospital/analytics/doctor",
        params: {
          user_id: parseInt(user.id),
          year: new Date().getFullYear(),
        },
      });

      if (lab === "error" || res === "error") {
        return;
      }

      setRecentRequests(res.recent_requests);
      setTotalRequests(res.total_requests);
      setQueue(res.total_doctor_queues.total_doctor_queue);
      setPatientsSeen(res.patients_seen_today);
      setLab(lab.lab_queue);
      setMVisits(res.monthly_patients);
      setWVisits(res.weekly_patients);
      setUserQueue(res.patients_in_queue);
      setLoading(false);
    } catch (e) {
      //console.log(e);
    }
  }

  useEffect(() => {
    getInitialData();
  }, []);

  return (
    <div className="w-full  bg-ghost_white">
      <div className="h-full px-10 ">
        <Navbar />
      </div>
      <div className="flex justify-between gap-x-5 py-10 px-10 bg-ghost_white w-full">
        <div className="w-9/12 ">
          <PatientsSeen patientsSeen={patientsSeen} queue={queue} lab={lab} />
        </div>
        <div className="w-3/12 mx-auto">
          <TotalRequests totalRequests={totalRequests} />
        </div>
      </div>
      <div className="flex justify-between gap-x-5 p-10 w-full bg-ghost_white">
        <div className="w-7/12">
          <PatientsChart mVisits={mVisits} />
        </div>
        <div className="w-4/12 mx-auto">
          <Visits wVisits={wVisits} />
        </div>
      </div>
      <div className="flex justify-between gap-x-5 mb-10 p-10 w-full bg-ghost_white">
        <div className="w-8/12">
          <Queue userQueue={userQueue} />
        </div>
        <div className="w-4/12">
          <RecentRequests recentRequests={recentRequests} />
        </div>
      </div>
    </div>
  );
};

export default Summary;
