import BasicModal from '../../../../../../Components/Modals/BasicModal'
import React from 'react'


const viewComments = ({
    stepComments,
  showModal,
  setShowModal,
  commenter,
}) => {


  return (
    <BasicModal showModal={showModal} setShowModal={setShowModal}>
      <p className="flex w-full justify-center text-2xl font-DM-Bold text-gray-700">
        Visit Comments
      </p>
      <div
        style={{ width: "60em" }}
        className=" flex items-center justify-center pb-7"
      >
        <div className="w-full ml-5 mt-8 mr-5 bg-white rounded-lg shadow-md ">
          <div className="mb-5 pr-6">
          <h1 className="text-center text-lg text-gray-600 my-5">
            Comment by: <span className='font-semibold'>{commenter && commenter}</span>
           </h1>
           <h1 className="text-center text-lg text-gray-600 ">
            {stepComments}
           </h1>
          </div>
        </div>
      </div>
    </BasicModal>
  );
};

export default viewComments;