import React from "react";

const PrescriptionCard = ({ doc = {}, setListData = () => {} }) => {
  function closeItem() {
    setListData((c) => {
      let newItems = { ...c };
      delete newItems[doc.id];
      return newItems;
    });
  }

  return (
    <div className="w-full p-5 rounded-2xl shadow-lg leading-8 border border-gray-200 mt-3">
      <div className="">
        <div className="w-full">
          <p className="text-gray-900 font-medium px-2 py-1">{`Name : ${doc.inventory_items.name}`}</p>
          <p className="text-gray-900 font-medium border-t px-2 py-1">
            {`Code : ${doc.inventory_items.code}`} 0001
          </p>
          <p className="text-gray-900 font-medium border-t border-b py-1 px-2">{`Unit of Measure : ${doc.inventory_items.unit_of_measures.name}`}</p>
          <p className="text-gray-900 font-medium border-t border-b py-1 px-2">{`Price : ${doc.price}`}</p>
        </div>
        <p
          className="w-32 mx-auto flex justify-center items-center text-white bg-primary cursor-pointer rounded-md mt-5"
          onClick={closeItem}
        >
          Remove
        </p>
      </div>
    </div>
  );
};

export default PrescriptionCard;
