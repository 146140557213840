import React, { useState, useEffect } from "react";
import btns from "./btn_list";

import TextArea from "./../components/TextArea";

import BtnRounded from "../../../../../../../../Components/Buttons/BtnRounded";

const Notes = ({ input = "", setInput = () => {}, active = 0, note = {} }) => {
  return (
    <div className=" flex-1 px-4 ">
      <TextArea
        input={input}
        setInput={setInput}
        label={btns[active]}
        note={note}
      />
      {/* <div className="flex justify-center mt-14">
        <BtnRounded>
          <span>Submit</span>
        </BtnRounded>
      </div> */}
    </div>
  );
};

export default Notes;
