import React, { useState, useEffect } from "react";
import BasicModal from "../../../../../../../Components/Modals/BasicModal";

import SideBar from "./sections/SideBar";

import NoteSection from "./sections/NoteSection";

import btns from "./sections/btn_list";

import useApp from "../../../../../../../Hooks/useApp";

import useAxios from "../../../../../../../Hooks/useAxios";

const note_keys = [
  "sign_and_symptoms",
  "hpi",
  "gynecological_history",
  "family_social_history",
  "nutritional_history",
  "past_medical_history",
  "systemic_review",
  "impression",
  "investigation",
  "diagnosis",
  "treatment",
];

const OtherNotes = ({
  showModal = false,
  setShowModal = () => {},
  n = {},
  handleUpdate = () => {},
}) => {
  const [active, setActive] = useState(0);
  const [note, setNote] = useState(n);
  const [input, setInput] = useState("");
  const [init, setInit] = useState(false);
  const [st, setSt] = useState(false);

  const request = useAxios();

  const { user, setShowRequestModal, setModalMessage } = useApp();

  useEffect(() => {
    let newNote = { ...note };
    let obj_key = note_keys[active]?.toLowerCase() || "";
    setInput(newNote[obj_key]);
    setSt(true);
  }, [active]);

  useEffect(() => {
    if (!st) return;
    let newNote = { ...note };

    let obj_key = note_keys[active]?.toLowerCase() || "";
    newNote[obj_key] = input;
    setNote(newNote);
  }, [input, st]);

  async function CloseModal() {
    if (parseInt(user.id) === parseInt(note.user_id)) {
      await request({
        method: "PUT",
        url: "hospital/note-v2",
        data: {
          ...note,
          note_id: parseInt(note.id),
          user_id: parseInt(user.id),
          visit_id: parseInt(note.visit_id),
        },
      });
      await handleUpdate();
      setShowModal(false);
      return;
    }

    setModalMessage("!! Can't Edit Another Doctors Notes");
    setShowRequestModal(true);
    setShowModal(false);
  }

  // async function handleSubmit() {
  //   let res = await request({
  //     method: "PUT",
  //     url: "hospital/note-v2",
  //     data: {
  //       ...note,
  //       note_id: parseInt(note.id),
  //       user_id: parseInt(user.id),
  //       visit_id: parseInt(note.visit_id),
  //     },
  //   });
  // }

  return (
    <BasicModal
      customFunc={true}
      func={CloseModal}
      showModal={showModal}
      setShowModal={setShowModal}
    >
      <div className="" style={{ width: "80vw" }}>
        <h2 className="font-DM-Reg text-xl text-black text-center">
          Doctor: {note.team ? note.team.name : ""} Notes
        </h2>
        <div className=" w-full flex my-4">
          <SideBar active={active} setActive={setActive} />
          <NoteSection
            note={note}
            active={active}
            input={input}
            setInput={setInput}
          />
        </div>
      </div>
    </BasicModal>
  );
};

export default OtherNotes;
