import React, { useRef, useState } from "react";
import BasicModal from "../../../../../../../Components/Modals/BasicModal";
import TextInput from "../../../../../../../Components/Inputs/TextInput";
import TextArea from "../../../../../../../Components/Inputs/TextBox";
import useApp from "../../../../../../../Hooks/useApp";
import useAxios from "../../../../../../../Hooks/useAxios";

import useHandleFormError from "../../../../../../../Hooks/useHandleFormError";

import BtnBlock from "../../../../../../../Components/Buttons/BtnBlock";

import { IoMdArrowForward } from "react-icons/io";
import usePouchCud from "../../../../../Hooks/usePouchCud";

const VisitComments = ({
  showModal,
  setShowModal,
  doc,
  data,
  handleUpdate,
}) => {
  const [showError, setShowError] = useState(0);
  const input = useRef({ form: {}, err: {} });

  const request = useAxios();

  const formErrors = useHandleFormError();

  const { user, setModalMessage, setShowSuccessModal } = useApp();

  const { update } = usePouchCud();

  async function submit() {
    setShowError(showError + 1);
    if (formErrors({ input })) return;

    let obj = {
      visit_prescription_id: parseInt(doc.id),
      created_by: parseInt(user.id),
      name: input.current.form.name,
      description: input.current.form.description,
    };

    let res = await request({
      method: "POST",
      url: "hospital/visit/comment",
      data: obj,
    });

    if (res === "error") return;
    setModalMessage("Comments Added!");
    setShowSuccessModal(true);
    await update({ name: "visit_comments", doc: res });
    setShowModal(false);
    handleUpdate();
  }

  return (
    <BasicModal showModal={showModal} setShowModal={setShowModal}>
      <div className="flex flex-col w-full gap-y-8 p-10">
        <h2 className="font-DM-Reg text-xl text-black text-center">
          Add Comments
        </h2>
        <form className="lg:w-[517px] flex flex-col gap-y-6">
          <TextInput
            input={input}
            showError={showError}
            inputKey="name"
            mt={0.5}
            config={{
              required: false,
              label: "Name",
              placeholder: "Check for any other issue",
              type: "name",
              maxChar: 250,
              minChar: 2,
            }}
          />
          <TextArea
            input={input}
            showError={showError}
            inputKey="description"
            mt={0.5}
            config={{
              required: false,
              label: "Description",
              placeholder: "",
              type: "text",
              inputType: "text",
              maxChar: 1000,
              minChar: 4,
            }}
          />
          <BtnBlock click={submit} mt={2.5} bg="primary" textColor="white">
            <div className="flex w-full text-[16px] items-center justify-center px-2 py-1 space-x-2">
              <span>Add Comments</span>
              <span className="text-xl">
                <IoMdArrowForward />
              </span>
            </div>
          </BtnBlock>
        </form>
      </div>
    </BasicModal>
  );
};

export default VisitComments;
