import { TableRow, TableCell, TableText } from "../../../../../../../../Components/FinalTable";

const TableDetails=({ data})=>{
    return(
        <div>
            { data.map((docs, i)=>(
                 <TableRow mt={5} key={i} >
                     <TableCell w={100 / 4}>
                         <TableText label={docs.alergy_type}/>
                     </TableCell>
                     <TableCell w={100 / 4}>
                         <TableText label={docs.alergen}/>
                     </TableCell>
                     <TableCell w={100 / 4}>
                         <TableText label={docs.reaction}/>
                     </TableCell>
                 </TableRow>
             

            ))}
        </div>
    )
};

export default TableDetails;
