import React, { useEffect, useState } from "react";
import ListModal from "./ListModal";
import PrescriptionCard from "./PrescriptionCard";

const SelectedPrescriptions = ({
  showList,
  setShowList,
  listData = {},
  setListData = () => {},
  add,
}) => {
  const [docs, setDocs] = useState([]);

  useEffect(() => {
    let keys = Object.keys(listData);

    let ar = [];

    for (let key of keys) {
      ar.unshift(listData[key]);
    }
    setDocs(ar);
  }, [listData]);
  
  return (
    <ListModal showList={showList} setShowList={setShowList} add={add}>
      {docs.map((doc) => (
        <PrescriptionCard key={doc.id} doc={doc} setListData={setListData} />
      ))}
    </ListModal>
  );
};

export default SelectedPrescriptions;
