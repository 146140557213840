import React, { useContext } from "react";

import {
  TableCell,
  TableRow,
  TableText,
} from "../../../../../../Components/FinalTable";

import TContext from "./TContext";

import SingleCheckBox from "../../../../../../Components/FinalTable/components/Actions/SingleCheckBox";

const TRow = ({ doc }) => {
  const { checked_id, setCheckedId } = useContext(TContext);

  return (
    <TableRow key={doc.id}>
      <TableCell w={90 / 5}>
        <TableText label={doc.investigations.name} />
      </TableCell>
      <TableCell w={90 / 5}>
        <TableText label={doc.investigations.code} />
      </TableCell>
      <TableCell w={90 / 5}>
        <TableText label={doc.investigations.regular_price} />
      </TableCell>
      <TableCell w={90 / 5}>
        <TableText label={doc.investigations.description} />
      </TableCell>
      <TableCell>
        <SingleCheckBox
          checked_id={checked_id}
          setCheckedId={setCheckedId}
          id={doc.id}
        />
      </TableCell>
    </TableRow>
  );
};

export default TRow;
