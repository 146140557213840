import React, { useState, useEffect } from "react";

import { colors } from "./../../../../../../../../Util/colors";

import useApp from "../../../../../../../../Hooks/useApp";

const TextArea = ({
  input = "",
  setInput = () => {},
  label = "",
  placeholder = "",
  mt = 0,
  mb = 0,
  note = {},
}) => {
  const [borderColor, setBorderColor] = useState("bright_grey");

  const { user, setShowRequestModal, setModalMessage } = useApp();

  //console.log(user.id);
  function handleChange(txt) {
    if (parseInt(user.id) === parseInt(note.user_id)) {
      setInput(txt);
      return;
    }
    setModalMessage("!! Can't Edit Another Doctors Notes");
    setShowRequestModal(true);
  }
  return (
    <div
      style={{
        marginTop: `${mt}em`,
        marginBottom: `${mb}em`,
        height: "95%",
      }}
    >
      <label className=" font-DM-Reg text-base mb-2">{label}</label>
      <textarea
        className={`border-2 rounded-lg w-full   py-2 px-8 placeholder-cadet_grey `}
        onFocus={() => setBorderColor("primary")}
        style={{
          borderColor: colors[borderColor],
          minHeight: "100%",
        }}
        placeholder={placeholder}
        value={input}
        onChange={(e) => handleChange(e.target.value)}
      />
    </div>
  );
};

export default TextArea;
