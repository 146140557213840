import { useState, useEffect } from 'react';

import Infosection from './Infosection';
import TableDetails from "./TableDetails";
import TableHeading from "./TableHeading";

import { Table } from "../../../../../../../../Components/FinalTable";

import DiagnosesAll from "../../context/DiagnosesContext";

import useAxios from '../../../../../../../../Hooks/useAxios';

import { useNavigate } from "react-router-dom";

const DiagnosisTable = ({ id }) => {

  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);

  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);

  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPrevPage, setHasPrevPage] = useState(false);

  const request = useAxios();
  
  const navigate = useNavigate();

  const Title = [
    {id:1, name:"DATE"},
    { id:2, name: "DOCTOR"},
    { id:3, name: "DIAGNOSIS"},
     
  ];
  const showPagination = true;

  const db_name = "visit_notes";

  useEffect(()=>{
    const getData = async()=>{

      const doc = await request({
        url:`patient/history?patient_id=${id}&type=${db_name}&page=${page}&limit=${limit}`
      });
      
      if ( doc === "error" ){
        navigate("/dashboard/user-queue");
        return;
      }
      
      setData(doc.docs);
  
      setHasNextPage(doc.hasNextPage);
  
      setHasPrevPage(doc.hasPrevPage);
  
      setTotal(doc.total_docs);

    }
    getData()
  // eslint-disable-next-line
  },[ limit, page]);


  useEffect(()=>{
    setPage(1);
  },[limit])

  function handleNext(){
    setPage(page+1)
  }

  function handlePrev(){
    setPage(page-1)
  }
  
  return (
    <DiagnosesAll.Provider
    value={{
      limit,
      setLimit,
      data,
      setData,
      page,
      hasNextPage,
      hasPrevPage,
      handleNext,
      handlePrev,
      total,
      showPagination,
    }}>
      <div className="">
        <div className="flex justify-between w-[78%] pt-4 mt-10 pl-4">
          <h2 className="font-medium text-2xl">Diagnoses</h2>
        </div>
        <div className="">
          <Table>
            <TableHeading Titles={Title} />
            <TableDetails data={data}/>
            <Infosection />
          </Table>
        </div>
          
        
      </div>
    </DiagnosesAll.Provider>
  );

}

export default DiagnosisTable;
